import {combineReducers} from "redux";
import {firebaseReducer, FirebaseReducer, FirestoreReducer } from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";
import windowReducer from './Window';
import sideMenuReducer from './SideMenu';
import modalReducer from './Modal';
import postFilterReducer from './PostFilter';
import listReducer from './List';
import commentListReducer from './CommentList';
import footerReducer from './Footer';

interface Schema {
    //posts: IPost;
}

export interface RootState {
    firebase: FirebaseReducer.Reducer<{}, Schema>,
    firestore: any,
    window: any,
    sideMenu: any,
    modal: any,
    postFilter: any,
    list: any,
    commentList: any,
    footer: any,
}

export const rootReducer = combineReducers<RootState>({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    window: windowReducer,
    sideMenu: sideMenuReducer,
    modal: modalReducer,
    postFilter: postFilterReducer,
    list: listReducer,
    commentList: commentListReducer,
    footer: footerReducer,
});