export const MODAL_STATE = 'MODAL_STATE'
export const MODAL_TYPE = {
    LOGIN : 'LOGIN',
    SIGNUP : 'SIGNUP',
    RESET_PASSWORD : 'RESET_PASSWORD',
    EDIT_POST : 'EDIT_POST',
    FLAG_POST : 'FLAG_POST',
    ABOUT : 'ABOUT',
    RULES : 'RULES', 
    CONTACT : 'CONTACT',
    TERMS : 'TERMS', 
    PRIVACY : 'PRIVACY',
    CLOSE : 'CLOSE'
}

const triggerLoginModal = () => {
    return { 
        type: MODAL_TYPE.LOGIN, 
        visible: true
    };
}

const triggerSignupModal = () => {
    return { 
        type: MODAL_TYPE.SIGNUP, 
        visible: true
    };
}

const triggerResetPassword = () => {
    return { 
        type: MODAL_TYPE.RESET_PASSWORD, 
        visible: true
    };
}

const triggerEditPostModal = (editPostProps: Map<string, any>) => {
    return { 
        type: MODAL_TYPE.EDIT_POST, 
        visible: true,
        props: editPostProps,
    };
}

const triggerFlagPostModal = (flagPostProps: Map<string, any>) => {
    return { 
        type: MODAL_TYPE.FLAG_POST, 
        visible: true,
        props: flagPostProps
    };
}

const triggerAboutModal = () => {
    return { 
        type: MODAL_TYPE.ABOUT, 
        visible: true,
    };
}

const triggerRulesModal = () => {
    return { 
        type: MODAL_TYPE.RULES, 
        visible: true,
    };
}

const triggerContactModal = () => {
    return { 
        type: MODAL_TYPE.CONTACT, 
        visible: true,
    };
}

const triggerTermsModal = (stacked: boolean) => {
    return { 
        type: MODAL_TYPE.TERMS, 
        visible: true,
        stacked: stacked,
    };
}

const triggerPrivacyModal = (stacked: boolean) => {
    return { 
        type: MODAL_TYPE.PRIVACY, 
        visible: true,
        stacked: stacked,
    };
}

const triggerModalClose = () => {
    return {
        type: MODAL_TYPE.CLOSE,
        visible: false
    };
}

export { triggerLoginModal, 
         triggerSignupModal, 
         triggerResetPassword,
         triggerEditPostModal, 
         triggerFlagPostModal, 
         triggerAboutModal,
         triggerRulesModal,
         triggerContactModal,
         triggerTermsModal,
         triggerPrivacyModal,
         triggerModalClose };