import React, { useEffect} from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { triggerModalClose, triggerSignupModal, MODAL_TYPE, } from '../../Actions/Modal';
import { LeftOutlined } from '@ant-design/icons';
import LoginForm from '../Input/LoginForm';
import SignupForm from '../Input/SignupForm';
import EditPostForm from '../Input/EditPostForm';
import FlagPostForm from '../Input/FlagPostForm';
import AboutInfo from '../Info/AboutInfo';
import RulesInfo from '../Info/RulesInfo';
import ContactInfo from '../Info/ContactInfo';
import TermsInfo from '../Info/TermsInfo';
import PrivacyInfo from '../Info/PrivacyInfo';
import { DEVICES } from '../../Consts';
import ResetPasswordForm from '../Input/ResetPasswordForm';

interface ModalBaseProps {
}

const ModalBase = (props: ModalBaseProps) => {
  const dispatch = useDispatch();
  const modalState = useSelector((state: any) => state.modal);
  const windowState = useSelector((state: any) => state.window);  

  const onSubmitCancel = (e: any) => {
    dispatch(triggerModalClose());
  }

  const getModalContent = () => {
    switch(modalState.type) {
      case MODAL_TYPE.SIGNUP: {
        return (
          <SignupForm onSubmit={onSubmitCancel} visible={modalState.visible}/>
        );
      }
      case MODAL_TYPE.LOGIN: {
        return (
          <LoginForm onSubmit={onSubmitCancel} visible={modalState.visible}/>
        );
      }
      case MODAL_TYPE.RESET_PASSWORD: {
        return (
          <ResetPasswordForm onSubmit={onSubmitCancel} visible={modalState.visible}/>
        );
      }
      case MODAL_TYPE.EDIT_POST: {
        return(
          <EditPostForm onSubmit={onSubmitCancel} visible={modalState.visible} post={modalState.props.get("post")}/>
        );
      }
      case MODAL_TYPE.FLAG_POST: {
        return(
          <FlagPostForm onSubmit={onSubmitCancel} postId={modalState.props.get("postId")}/>
        );
      }
      case MODAL_TYPE.ABOUT: {
        return(
          <AboutInfo/>
        );
      }
      case MODAL_TYPE.RULES: {
        return(
          <RulesInfo/>
        );
      }
      case MODAL_TYPE.CONTACT: {
        return(
          <ContactInfo/>
        );
      }
      case MODAL_TYPE.TERMS: {
        return(
          <TermsInfo/>
        );
      }
      case MODAL_TYPE.PRIVACY: {
        return(
          <PrivacyInfo/>
        );
      }
      default : break;
    }
    return(<></>);
  };

  useEffect(() => {

  },[modalState]);

  return (
    <>
      <Modal
          zIndex = {1001 /* So it will open over drawer*/}
          bodyStyle = {{backgroundColor: 'rgba(245,245,245,1)'}}
          visible = {modalState.visible}
          title = {modalState.title}
          onCancel = {onSubmitCancel}
          footer = {[null]}>
          <Row justify="center" style={{maxHeight:'60vh', overflow:'auto'}}>
              <Col span={windowState.device <= DEVICES.TabletSm ? 24 : 16}>
              { getModalContent() }
              </Col>
          </Row>
          {modalState.stacked &&
            <Button type="text" size="small" icon={<LeftOutlined/>} onClick={()=> {dispatch(triggerSignupModal())}} style={{marginTop:'-6px', fontSize:'12px'}}><b>Back</b></Button>
          }
      </Modal> 
    </>
  );
}
export default ModalBase;
