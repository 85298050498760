import React, {useState, useEffect} from 'react';
import {Card, Button, Row, Col, Tag, Divider, Avatar} from 'antd';
import { UserOutlined, CalendarOutlined, LeftOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import CommentSection from '../../Lists/CommentSection';
import FavoritePostButton from '../../Buttons/FavoritePostButton';
import FlagPostButton from '../../Buttons/FlagPostButton'
import UpvoteDownvote from '../../Buttons/ButtonGroups/UpvoteDownvote';
import {useHistory, useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {useFirestore } from 'react-redux-firebase'
import { DEVICES } from '../../../Consts';
import Utils from '../../../Utils';

interface LocationState {
    post : IPost
}

const PostPage = (props: any) => {
    const firestore = useFirestore();
    const [loading, setLoading] = useState(true);
    const location = useLocation<LocationState>();
    const history = useHistory();
    const [postData, setPostData] = useState(Utils.emptyIPost);
    const windowState = useSelector((state: any) => state.window);

    function videoReady() {
        console.log("Video Ready");
    }

    useEffect(() => {
        // Location state is undefined if you navigate directly to the link in a browser.
        // Otherwise, the user has clicked the post and we passed in the data.
        const postId:string = location.pathname.replace("/post/", "");
        if(location.state == undefined) {
            firestore.collection("posts").doc(postId).get().then((postDoc) => {
                setPostData(Utils.docToIPost(postDoc,0));
                setLoading(false);
            }).catch((error)=> {
                console.log(error);
                setLoading(false);
            });
        } else {
            setPostData(location.state.post);
            setLoading(false);
        }
        window.scrollTo(0,0);
    }, [])

    return (
        <Row justify={"center"}>
            <Col span={windowState.device <= DEVICES.Tablet ? 24 : 15} style={{maxWidth:'1000px'}}>
                <Row justify={"center"}>
                    <Col span={24}>
                        <Card title= {null} loading={loading} extra={null} bodyStyle={{paddingBottom:'6px', padding: (windowState.device < DEVICES.Tablet) ? '18px 6px 18px 6px' : '' }}>
                                <Button type="text" size="small" icon={<LeftOutlined/>} onClick={()=> {history.push('/');}} style={{marginTop:'-6px', fontSize:'12px'}}><b>List</b></Button>
                                {postData.mediaLink.type == "VIDEO" ?
                                    <div className = "player-wrapper">
                                        <ReactPlayer url={postData.mediaLink.url}
                                            playing={false} 
                                            width='100%' 
                                            height='100%'
                                            controls={true}
                                            alt={"Video of source " + postData.mediaLink.url + " titled: " + postData.title}
                                            style={{}}/>
                                    </div>
                                    :
                                    <Row justify ={"center"}>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <a href={postData.mediaLink.url}>
                                                <img src={postData.mediaLink.url} 
                                                     style={{objectFit:'contain', width: 'auto', height:'auto', maxWidth:'100%', maxHeight:'60vh'}} 
                                                     alt={"Image of source " + postData.mediaLink.url + " titled: " + postData.title}/>
                                            </a>
                                        </Col>
                                    </Row>
                                }
                            <Divider>
                                <Tag icon={<CalendarOutlined/>}>{postData.year}</Tag> 
                            </Divider>
                            <Row style={{marginBottom:'5px'}}>
                                <Col span={22} style={{fontSize:'24px'}}><b>{postData.title}</b></Col>
                                <Col span={2} style={{ marginTop:'-20px'}}>
                                    <span style={{float:'right'}}><UpvoteDownvote postId={postData.id} upvotes={postData.upvotes}/></span>
                                </Col> 
                            </Row>
                            <Divider/>
                            <Row style={{marginBottom:'5px'}}>
                                <Col span={24}>{postData.history}</Col> 
                            </Row>
                            <Divider/>
                            <Row>
                                <Col span={12}>
                                <Avatar size={20}style={{fontSize:'8px', color: '#f56a00', backgroundColor:'#fde3cf', marginRight:'5px'}}>
                                        <UserOutlined/>
                                    </Avatar>
                                    <span>Uploaded By: </span>
                                    <i>{postData.uploaderDisplayName}</i>
                                </Col>
                                <Col span={12}>
                                    <div style={{float:'right'}}> 
                                    <FavoritePostButton post={postData} showCount={true}/>
                                    <FlagPostButton postId={postData.id}/>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Divider style={{marginTop:'0px'}}/>
                <Row justify={"center"}>
                    <Col span={windowState.device <= DEVICES.Tablet ? 23 : 24}>
                        <CommentSection postId={postData.id} numComments={postData.comments}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default PostPage;
