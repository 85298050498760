import React, {useState, useEffect} from 'react';
import {Input, Form, Button, Row, Col, Divider} from 'antd';
import { useFirestore } from "react-redux-firebase";
import YearSelector from '../Buttons/YearSelector';
import firebase from 'firebase/app';
import { useSelector, useDispatch } from 'react-redux';
import { triggerPostListReload } from '../../Actions/List';
import { DEVICES } from '../../Consts';
import Utils from '../../Utils';

const { TextArea } = Input;

interface EditPostFormProps {
    onSubmit: Function;
    visible: boolean;
    post: IPost;
}

const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 20,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 5,
        span: 20,
    },
};

const EditPostForm = (props: EditPostFormProps) => {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const windowState = useSelector((state: any) => state.window);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();
    const [year, setYear] = useState(props.post.year);
    const auth = useSelector((state: any) => state.firebase.auth);

    const yearChange = (year: number) => {
        setYear(year);
    }

    const onFinish = (values: any) => {
        // check captcha
        if(values.information == undefined || values.information == "") {
            setLoading(true);
            // NEED UPDATE FAVORITE POSTS
            const editPost = {  
                title: values.title, 
                year: year,
                history: values.history,
                searchTerms: Utils.createSearchTerms(values.title.toLowerCase()),
            }
            firestore.collection('posts').doc(props.post.id).update(editPost).then(succes => {
                dispatch(triggerPostListReload());
                setLoading(false);
                form.resetFields();
                props.onSubmit();
            }).catch(error => {
                setLoading(false);
            });
        } else {
            props.onSubmit();
        }
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.resetFields();
    }, [props.visible]);

    return (
        <Row justify="center">
            <Form
                {...layout}
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                >
                <Row style={{textAlign:'center'}}>
                    <Col span={24}>
                        <span style={{fontSize:'26px'}}><b>{year}</b></span>
                    </Col>
                </Row>
                <Divider style={{marginTop:'12px'}}/>
                <Form.Item
                    label="Title"
                    name="title"
                    initialValue={props.post.title}
                    rules={[
                    { required: true, message: 'Title is required'},
                    { max: 100, message: 'Title must be 100 characters or less'}
                    ]}>
                    <Input placeholder={'Post title!'}/>
                </Form.Item>
                <Form.Item
                    label="History"
                    name="history"
                    initialValue={props.post.history}
                    rules={[
                        { required: true, message: 'Link is required'},
                    ]}>
                    <TextArea placeholder={'History of the post'}/>
                </Form.Item>
                <div className="cap">
                    <Form.Item
                        name="information"
                        label="Information"
                    >
                        <Input />
                    </Form.Item>
                </div>
                
                <Form.Item>
                    <Row justify={"center"}>
                        <Col offset={windowState.device <= DEVICES.TabletSm ? 0 : 10}>
                            <div style={{maxWidth:'330px'}}>
                                <YearSelector onYearChange={yearChange} enabled={true} selected={year}/>
                            </div>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item {...(windowState.device <= DEVICES.TabletSm ? null : tailLayout)}>
                    <Button type="primary" block={true} loading={loading} htmlType="submit">
                        Edit
                    </Button>
                    <br/>
                    <span style={{color:'red', fontSize:'10px'}}><i>{errorMessage}</i></span>
                </Form.Item>
            </Form>     
        </Row>               
    );
}

export default EditPostForm;
