import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Tag, Avatar } from 'antd';
import { UserOutlined, CommentOutlined} from '@ant-design/icons';
import ReactPlayer from 'react-player';
import FavoritePostButton from '../Buttons/FavoritePostButton';
import FlagPostButton from '../Buttons/FlagPostButton';
import DeletePostButton from '../Buttons/DeletePostButton';
import EditPostButton from '../Buttons/EditPostButton';
import UpvoteDownvote from '../Buttons/ButtonGroups/UpvoteDownvote';
import AcceptDenyPost from '../Buttons/ButtonGroups/AcceptDenyPost';
import { POST_LIST_TYPE } from '../../Consts';

interface PostListItemProps {
    post: IPost;
    category: string;
    postListType: POST_LIST_TYPE;
}

const PostListItem = (props: PostListItemProps) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const cardClick = () => {
        let postPath = "/post/"+ props.post.id;
        history.push(postPath, {post:props.post});
    }

    return (
        <>
            <Card style={{minWidth:'100%', width:'100%'}} onClick={cardClick} hoverable={true} loading={loading} bodyStyle={{paddingTop:'7px', paddingBottom:'0px'}}>
                <Row>
                    <Col span={22}>
                        <Row>
                        <span>
                            {(props.category == "Top" && (props.postListType == POST_LIST_TYPE.Filtered_Query)) && 
                                <span style={{marginRight:'12px', fontSize:'16px'}}>
                                    <b>#{props.post.rank}</b>
                                </span>} 
                            {/*(props.postListType ==POST_LIST_TYPE.User_Posts) && 
                                <span style={{marginLeft:'-8px', marginRight:'8px'}}>
                                    <DeletePostButton postId = {props.post.id}/> 
                                    <EditPostButton post ={props.post}/>
                            </span> 
                            [NOTE] edit/delete post TBD*/}
                            <Tag color={"cyan"}>{props.post.year}</Tag> 
                        </span>
                        </Row>
                        <Row>
                            <Col style={{paddingTop:'8px', paddingBottom:'8px', fontWeight:500}}>{props.post.title}</Col>
                        </Row>
                    </Col>
                    <Col span={2} style={{textAlign:'end'}}>
                        <Row justify="end">
                            <Col style={{marginTop:'-9px', marginRight:'-12px', marginBottom:'-8px'}}>
                                {props.postListType != POST_LIST_TYPE.Pending_Posts && <UpvoteDownvote postId={props.post.id} upvotes={props.post.upvotes}/>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Col span={24}>
                        {props.post.mediaLink.type == "VIDEO" ?
                              <div className = "player-wrapper">
                                <ReactPlayer url={props.post.mediaLink.url} 
                                             playing={false} /*light={true}*/ 
                                             controls={true} 
                                             width='100%' 
                                             height='100%' 
                                             alt={"Video of source " + props.post.mediaLink.url + " titled: " + props.post.title}/>
                              </div>
                              :
                              <img src={props.post.mediaLink.url} 
                                   style={{width: '100%'}} 
                                   alt={"Image of source " + props.post.mediaLink.url + " titled: " + props.post.title}/>
                            }
                    </Col>
                </Row>
                <Row  style={{paddingTop:'6px', paddingBottom:'8px'}}>
                    <Col span={24}>
                        {/*
                        <Avatar size={18} style={{color: '#f56a00', backgroundColor:'#fde3cf', marginRight:'5px'}}>
                            <UserOutlined/>
                        </Avatar>
                        <i>{props.post.uploaderDisplayName}</i> 
                        [NOTE] Removing user info for now, is it relevant?*/}
                        <span style={{float:'left', fontSize:'15px'}}>
                            {(props.postListType == POST_LIST_TYPE.Pending_Posts) ?
                            <AcceptDenyPost postId={props.post.id}/> :
                            <>{props.post.comments} 
                            <CommentOutlined style={{fontSize:'16px', marginLeft:'7px', marginRight:'7px'}}/>
                            <span style={{marginRight:'-7px', marginLeft:'-7px'}}>
                                <FavoritePostButton post={props.post} showCount={false}/>
                            </span>
                            <span style={{marginRight:'-7px'}}>
                                <FlagPostButton postId={props.post.id}/>
                            </span></>}
                        </span>
                    </Col>
                </Row>
            </Card>
        </>
    );
}
export default PostListItem;
