import React, {useState, useEffect} from 'react';
import {Button, Tooltip, Modal} from 'antd';
import { HeartTwoTone, HeartFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase'
import { triggerPostListReload } from '../../Actions/List';
import firebase from 'firebase/app';
import Utils from '../../Utils';

interface FavoritePostButtonProps {
    post : IPost,
    showCount: boolean,
}

const FavoritePostButton = (props: FavoritePostButtonProps) => {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.firebase.auth);
    const postFilterState = useSelector((state: any) => state.postFilter);
    const [postLiked, setPostLiked] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [like, setLike] = useState(0);

    const getMyFavoritesQuery = () : any => {
        return firestore.collection('favoritePosts').where("postId", "==", props.post.id)
                                                    .where("userId", "==", auth.uid);
    };

    const onClick = (e: any) => {
        if(!isEmpty(auth) && isLoaded(auth)) {
            if(postLiked) {
                triggerConfirmUnlikeModal(e);
            } else {
                favoritePost();
            }
        } else {
            Utils.showLoginMessage(dispatch);
        }
        e.stopPropagation();
    }

    const triggerConfirmUnlikeModal = (e: any) => {
        Modal.confirm({
            title: 'Unfavorite Post?',
            content: 'Post will be removed from your favorites...',
            maskClosable: true,
            cancelText: 'Cancel',
            okText: 'Ok',
            icon: <ExclamationCircleFilled />,
            onOk: unfavoritePost,
            onCancel: cancelUnfavoritePost,
        });
        e.stopPropagation();
    }
    const cancelUnfavoritePost = () => {
        Modal.destroyAll();
    }

    const favoritePost = () => {
        const likeCount: number = isFavorite ? 0 : 1;
        setLike(likeCount);
        setPostLiked(true);
    
        let batch = firestore.batch();
        let postRef = firestore.collection('posts').doc(props.post.id);
        batch.update(postRef, {likes: firestore.FieldValue.increment(1)});
        let favoritePostRef = firestore.collection('favoritePosts').doc();
        batch.set(favoritePostRef, {
            userId : auth.uid,
            postId : props.post.id,
            addedTimeStamp : Utils.getFirebaseTimeStamp(),
            year : props.post.year,
            urlType : props.post.mediaLink.type
        });
        batch.commit().then((success) => {
            console.log("Success", success);
        }).catch((error) => {
            console.log("Error", error);
        });
    };

    const unfavoritePost = () => {
        const likeCount: number = isFavorite ? -1 : 0;
        setLike(likeCount);
        setPostLiked(false);

        const myFavoritesQuery = getMyFavoritesQuery();
        myFavoritesQuery.get().then((query: any) => {
            let batch = firestore.batch();
            query.forEach((doc: any) => {
                let favoritePostRef = firestore.collection('favoritePosts').doc(doc.id);
                batch.delete(favoritePostRef);
            });
            let postRef = firestore.collection('posts').doc(props.post.id);
            batch.update(postRef, {likes: firestore.FieldValue.increment(-1)});
            batch.commit().then((success) => {
                console.log("Success", success);
                if(postFilterState.category == "Favorites") {
                    dispatch(triggerPostListReload());
                }
            }).catch((error) => {
                console.log("Error", error);
            });
        });
    };

    useEffect(() => {
        let mounted = true;
        if(!isEmpty(auth) && isLoaded(auth)) {
            setLike(0);
            setPostLiked(false);
            const myFavoritesQuery = getMyFavoritesQuery();
            myFavoritesQuery.get().then((query: any) => {
               if(mounted) {
                    if(!query.empty) {
                        setPostLiked(true);
                        setIsFavorite(true);
                    }
                }
           });
        }
        return () => {
            // Fix for state update on unmounted component.
            mounted = false;
        }
      }, [props.post, auth]);

    return (
        <>
        <Tooltip placement="bottomRight" title={postLiked? "Unfavorite" : "Favorite"} arrowPointAtCenter>
            <span style={{fontSize:'15px'}}>
                {props.showCount && (props.post.likes + like)}
                <Button type="text" onClick={onClick} 
                    icon={ postLiked ? <HeartFilled style={{color:'red'}} /> :
                                        <HeartTwoTone twoToneColor="#eb2f96"/> }>
                </Button>
            </span>
        </Tooltip>
        </>
    );

}
export default FavoritePostButton;