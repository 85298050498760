import React from 'react';
import {Button} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import {setMobileSideMenuVisible} from '../../Actions/SideMenu'
import Utils from '../../Utils';
import { YEAR_MAX } from '../../Consts';

const CreatePostButton = (props: any) => {
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.firebase.auth);
    const history = useHistory();

    const createPostButtonClick = () => {
        if(isLoaded(auth) && !isEmpty(auth)) {
            //dispatch(triggerCreatePostModal(new Map([["defaultYear", YEAR_MAX]])));
            dispatch(setMobileSideMenuVisible(false));
            history.push('/create/'+auth.uid, {defaultYear: YEAR_MAX});
        } else {
            Utils.showLoginMessage(dispatch);
        }
    };

    return (
        <>
            <Button style={{width:'95%', borderColor:'rgba(245,245,245,1)'}} onClick={createPostButtonClick} type="ghost" block={true} icon={<PlusOutlined />}>
                Create
            </Button>
        </>
    );

}
export default CreatePostButton;
