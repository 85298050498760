import React, {useEffect} from 'react';
import {Card} from 'antd';

interface FixedAdSpaceProps {
}

const FixedAdSpace = (props: FixedAdSpaceProps) => {

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4028724814951509";
        script.crossOrigin = "anonymous";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return (
        
        <Card style={{width:'100%'}}>
            <div style={{textAlign:'center', height:'360px'}}>
                <span>Advertisement</span>
            </div>
        </Card>
    );
}

export default FixedAdSpace;