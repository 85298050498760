import { UPDATE_DEVICE } from '../Actions/Window';
import Utils from '../Utils';

const initialState = {
  device: typeof window === 'object' ? Utils.getDevice(window.innerWidth) : null
};

function windowReducer(state = initialState, action: any) {
  switch (action.type) {
      case UPDATE_DEVICE:
          return {
              device: action.device
          };
  }
  return state;
}

export default windowReducer;