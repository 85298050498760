import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Button, Modal} from 'antd'
import {DeleteFilled, ExclamationCircleFilled} from '@ant-design/icons'
import { isEmpty, useFirebase, useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';

const DeleteAccountButton = (props: any) => {
    const auth = useSelector((state: any) => state.firebase.auth);
    const history = useHistory();
    const firestore = useFirestore();
    const firebase = useFirebase();

    const onCancelClick = () => {
        Modal.destroyAll();
    }

    const onOkClick = () => {
        // Add delete favorites 
        let userData = firestore.collection('users').doc(auth.uid);
        userData.delete().then((success) => {
            let user: any = firebase.auth().currentUser;
            user?.delete().then((success: any) => {
                console.log(success);
            }).catch((error: any) => {
                let credential = firebase.reloadAuth();
                console.log(error)
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    const onDeleteAccountClick = (e: any) => {
        Modal.confirm({
            title: 'Delete account?',
            content: 'This cannot be undone..',
            maskClosable: true,
            cancelText: 'Cancel',
            okText: 'Ok',
            icon: <ExclamationCircleFilled />,
            onOk: onOkClick,
            onCancel: onCancelClick,
        });
        e.stopPropagation();
    };

    useEffect(() => {
        if(isEmpty(auth)) {
            history.push('/');
        }
    },[auth]);

    return (
        <>
            <Button type={'primary'} block={true} onClick={onDeleteAccountClick}icon={<DeleteFilled/>} danger>Delete Account</Button>
        </>
    );
}
export default DeleteAccountButton;
