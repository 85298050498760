import React, {useState} from 'react';
import {Tooltip, Button} from 'antd';
import { PictureOutlined, VideoCameraOutlined, AppstoreOutlined } from '@ant-design/icons';
import { COLORS } from '../../Consts';

interface PostTypeMenuProps {
    onTypeChange: Function,
    selected: string
}

const PostTypeMenu = (props: PostTypeMenuProps) => {

    const optionClick = (optionType: string) => {
        console.log(optionType);
        props.onTypeChange(optionType);
    }

    return (
        <>
            <Tooltip placement="bottom" title={'ALL'} arrowPointAtCenter>
                <Button type="link" onClick={() => optionClick('ALL')}
                        icon={ <AppstoreOutlined style={{paddingLeft:'5px', color: (props.selected =='ALL') ? COLORS.selectedPrimary : 'rgba(175,175,175,255)'}}/> }>
                </Button>
            </Tooltip>
            <Tooltip placement="bottom" title={'VIDEO'} arrowPointAtCenter>
                <Button type="link" onClick={() => optionClick('VIDEO')}
                        icon={ <VideoCameraOutlined style={{paddingLeft:'5px', color: (props.selected =='VIDEO') ? COLORS.selectedPrimary : 'rgba(175,175,175,255)'}}/> }>
                </Button>
            </Tooltip>
            <Tooltip placement="bottom" title={'IMAGE'} arrowPointAtCenter>
                <Button type="link" onClick={() => optionClick('IMAGE')}
                        icon={ <PictureOutlined style={{paddingLeft:'5px', color: (props.selected =='IMAGE') ? COLORS.selectedPrimary : 'rgba(175,175,175,255)'}}/> }>
                </Button>
            </Tooltip>
        </>
    );
}

export default PostTypeMenu;