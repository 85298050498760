import { FOOTER_VISIBLE } from '../Actions/Footer';

const initialState = {
  visible: true
};

function footerReducer(state = initialState, action: any) {
  switch (action.type) {
      case FOOTER_VISIBLE:
          return {
              visible: action.visible
          };
  }
  return state;
}

export default footerReducer;