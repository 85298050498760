import React, {useState} from 'react';
import {Input, AutoComplete} from 'antd';
import { useFirestore } from 'react-redux-firebase';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Utils from '../../Utils';
import {setSearchTerm, resetPostFilter} from '../../Actions/PostFilter';

const SearchBar = (props: any) => {
    const firestore = useFirestore();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [options, setOptions] = useState(Array<any>());

    const onSearchInput = (searchText: string) => {
        firestore.collection('posts').where("searchTerms", "array-contains", searchText.toLowerCase())
                                     .limit(10)
                                     .get()
                                     .then(query => {
                                        let autoCompleteOptions = new Array<any>();
                                        query.forEach(doc=> {
                                            let post = Utils.docToIPost(doc, 0);
                                            autoCompleteOptions.push({value: doc.data().title,
                                                                      postData: post});
                                        })
                                        setOptions(autoCompleteOptions);
                                    });
    };

    const onOptionClick = (value: any, option: any) => {
        let post = Object.entries(option)[1][1] as IPost;
        if(post != undefined) {
            let postPath = "/post/"+ post.id;
            history.push(postPath, {post: post});
        }
    };

    const onSearchClick = (searchText: string) => {
        if(searchText == '') {
            return;
        }
        if(location.pathname != '/') {
            dispatch(resetPostFilter());
            history.push('/');
        }
        dispatch(setSearchTerm(searchText.toLowerCase()));
    };

    return (
        <AutoComplete
            options={options}
            style={{
                width:'100%'
            }}
            onSearch={onSearchInput}
            onSelect={onOptionClick}
            >
            <Input.Search placeholder="Search" onSearch={onSearchClick} />
        </AutoComplete>
    );
}
export default SearchBar;
