import React, {useState, useEffect} from 'react';
import {Input, Form, Button, Row, Col, Divider, Modal} from 'antd';
import { CalendarTwoTone, UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { useFirestore } from "react-redux-firebase";
import YearSelector from '../Buttons/YearSelector';
import { useSelector, useDispatch } from 'react-redux';
import { DEVICES, COLORS } from '../../Consts';
import PreviewPost from '../Layouts/Content/PreviewPost';
import Utils from '../../Utils';
import { triggerRulesModal } from '../../Actions/Modal';
import { useHistory } from 'react-router-dom';

interface CreatePostFormProps {
    visible: boolean;
    defaultYear: number;
}

const {TextArea} = Input;

const CreatePostForm = (props: CreatePostFormProps) => {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const history = useHistory();
    const windowState = useSelector((state: any) => state.window);
    const [loading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [previewUrlType, setPreviewUrlType] = useState("INVALID");
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();
    const [year, setYear] = useState(props.defaultYear);
    const auth = useSelector((state: any) => state.firebase.auth);

    const onRulesClick = () => {
        dispatch(triggerRulesModal());
    }

    const yearChange = (year: number) => {
        setYear(year);
    }

    const onPreviewClick = () => {
        form.validateFields().then((success) => {
            Utils.determineMediaType(form.getFieldValue('url'), (urlType: string) => {
                setPreviewUrlType(urlType);
                if(urlType != "INVALID") {
                    setErrorMessage("");
                    setShowPreview(true);
                } else {
                    setErrorMessage("The link is invalid!");
                }
            });
        }).catch((error) => {

        });
    }

    const getPostPreview = () : IPost => {
        let postPreview: IPost = {
            id: '',
            rank: 0,
            likes: 0,
            upvotes: 0,
            mediaLink: {
                type: previewUrlType,
                url: form.getFieldValue('url'),

            },
            title: form.getFieldValue('title'), 
            history: form.getFieldValue('history'),
            comments: 0,
            uploaderDisplayName : auth.displayName,
            userId: auth.uid,
            year : year,
            created: 0,
        };  
        return postPreview;    
    }

    const onDismissPreview = () => {
        setShowPreview(false);
    }

    const onFinish = (values: any) => {
        console.log("Got ONFINISH");
        // check captcha
        if(values.information == undefined || values.information == "") {
            setLoading(true);
            Utils.determineMediaType(values.url, (urlType: string) => {
                if(urlType != "INVALID") {
                    const onModalOkayClick = () => {
                        //dispatch(triggerPostListReload());
                        setLoading(false);
                        form.resetFields();
                        history.push('/');
                    }
                    const newPost = {   title: values.title, 
                                        history: values.history,
                                        likes:0, 
                                        upvotes:0,
                                        url: values.url,
                                        urlType: urlType,
                                        year: year,
                                        created: Utils.getFirebaseTimeStamp(),
                                        uploaderDisplayName : auth.displayName,
                                        userId: auth.uid,
                                        searchTerms: Utils.createSearchTerms(values.title.toLowerCase()),
                                    }
                    firestore.collection('pendingPosts').add(newPost).then(succes => {
                        Modal.success({
                            zIndex: 1001,
                            title: 'Post Created!',
                            content: 'Thank you for your submission! In order to reduce spam and maintain content quality, we will review your post before it is made public.',
                            maskClosable: true,
                            okText: 'Ok',
                            onOk: onModalOkayClick,
                        });
                    }).catch(error => {
                        setLoading(false);
                    });
                } else {
                    setErrorMessage("The link is invalid!");
                    setLoading(false);
                }
            });
        }
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.resetFields();
    }, [props.visible]);

    return (
        <> 
            {showPreview ? <PreviewPost post={getPostPreview()} onDismiss={onDismissPreview}/> :
            <Form
            name="basic"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
                <Row style={{textAlign:'center'}}>
                    <Col span={24}>
                        <span><i>Add something funny...</i></span><br/>
                        <span style={{fontSize:'26px'}}> <CalendarTwoTone twoToneColor={'rgba(75,0,75,1)'} style={{marginRight:'5px'}}/> <b>{year}</b></span>
                    </Col>
                </Row>
                <Divider style={{marginTop:'12px'}}/>
                <Form.Item
                    label="Title"
                    name="title"
                    style={{marginBottom:'6px'}}
                    rules={[
                    { required: true, message: 'Title is required'},
                    { max: 100, message: 'Title must be 100 characters or less'}
                    ]}>
                    <Input placeholder={'Post title'}/>
                </Form.Item>
                <Form.Item
                    label="Link"
                    name="url"
                    style={{marginBottom:'6px'}}
                    rules={[
                        { required: true, message: 'Link is required'},
                    ]}>
                    <Input placeholder={'Image or video link'}/>
                </Form.Item>
                <Form.Item
                    label="History"
                    name="history"
                    initialValue={''}
                    style={{marginBottom:'6px'}}
                    rules={[
                        { required: true, message: 'History is required'},
                    ]}>
                    <TextArea placeholder={'Post history'}/>
                </Form.Item>
                <div className="cap">
                    <Form.Item
                        name="information"
                        label="Information"
                    >
                        <Input />
                    </Form.Item>
                </div>
                
                <Form.Item>
                    <Row justify={"center"}>
                        <Col span={10} style={{maxWidth: windowState.device <= DEVICES.Tablet ? '275px' : '350px'}}>
                            <div style={{textAlign:'center', color:'lightgray'}}>Year created:</div>
                            <YearSelector onYearChange={yearChange} enabled={true} selected={year}/>
                        </Col>
                    </Row>
                </Form.Item>

                <Row justify={"center"}><span style={{fontSize:'10px', marginBottom:'6px'}}>
                    <i>Please view the content submission <a onClick={onRulesClick}>rules.</a></i></span>
                </Row>
                <Divider/>

                <Form.Item>
                    <Row justify={"center"}>
                        <Col span={9}>
                            <Button type="primary" block={true} loading={loading} icon={<UploadOutlined/>} htmlType="submit">
                                Create
                            </Button>
                        </Col>
                        <Col span={9}>
                            <Button block={true} icon={<EyeOutlined/>} onClick={onPreviewClick}>
                                Preview
                            </Button>
                        </Col>
                        <Col span={24} style={{textAlign:'center'}}>
                            <span style={{color:'red', fontSize:'10px'}}><i>{errorMessage}</i></span>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>}
        </>               
    );
}

export default CreatePostForm;
