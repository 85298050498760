import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import {Row, Col, Avatar, Divider} from 'antd'
import {UserOutlined} from '@ant-design/icons'
import PostList from '../../Lists/PostList';
import DeleteAccountButton from '../../Buttons/DeleteAccountButton';
import ViewPendingPostsButton from '../../Buttons/ViewPendingPostsButton';
import { POST_LIST_TYPE } from '../../../Consts';

interface AccountDetailsProps {
    userRole: string;
}

const AccountDetails = (props: AccountDetailsProps) => {
    const auth = useSelector((state: any) => state.firebase.auth);
    const [listType, setListType] = useState(POST_LIST_TYPE.User_Posts);

    const onViewPendingClick = () => {
        const newType = listType == POST_LIST_TYPE.User_Posts ? POST_LIST_TYPE.Pending_Posts : POST_LIST_TYPE.User_Posts;
        setListType(newType);
    }

    return (
        <Col span={24} style={{maxWidth:'1200px'}}>
            <Row>
                <Col span={12}>
                    <Row justify={"center"}>
                        <Col span={18} style={{position:'fixed', marginTop:'40px'}}>
                            <span style={{fontSize:'24px'}}><b>Account Details:</b></span>
                            <div style={{width:'100%', textAlign:'center', marginBottom:'14px', marginTop:'14px'}}>
                            <Avatar style={{ backgroundColor: 'lightblue'}} size={75} icon={<UserOutlined />} />
                            </div>
                            <div style={{fontSize:'14px'}}>
                                <p>Username: <i>{auth.displayName}</i></p>
                                <p>Email: <i>{auth.email}</i></p>
                                {props.userRole == 'Moderator'  && 
                                <><p>Role: <i>Moderator</i></p>
                                <ViewPendingPostsButton onViewPendingClick={onViewPendingClick}/>
                                </>}
                            </div>
                            <br/>
                            <DeleteAccountButton/>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row justify={"center"}>
                        <Col span={18} style={{ marginTop:'24px'}}>
                            <PostList postListType={listType}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
}

export default AccountDetails;