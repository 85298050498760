export const COLORS = {
    selectedPrimary: '#1890ff',
    selectedSecondary: '#e6f7ff',
}
export const USER_ICON_COLORS = [
    'DarkCyan',
    'LightBlue',
    'PaleGreen',
    'LightSteelBlue',
    'Lavender'
]
export const DEVICES = {
    MobileSm: 320,
    MobileMd: 375,
    MobileLg: 425,
    TabletSm: 600,
    Tablet: 768,
    Laptop: 1024,
    LaptopLg: 1440,
    Monitor4K: 2560,
    UltraWide: Infinity 
}

export enum POST_LIST_TYPE  {
    Filtered_Query,
    User_Posts,
    Pending_Posts,
}

/*export const LINK_TYPE = {
    VIDEO: "VIDEO",
    IMAGE: "IMAGE",
    INVALID: "INVALID",
}*/

export const YEAR_MIN = 2000;
export const YEAR_MAX = new Date().getFullYear();