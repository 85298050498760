import React, {useEffect, useState} from 'react';
import {Button, Row, Col, Tag, Divider, Avatar} from 'antd';
import { UserOutlined, CalendarOutlined, LeftOutlined, UploadOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

interface PreviewPostProps {
    post : IPost
    onDismiss : Function
}

const PreviewPost = (props: PreviewPostProps) => {
    const windowState = useSelector((state: any) => state.window);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
            <Row justify={"center"}>
                <Col span={24}>
                    <Button type="text" size="small" icon={<LeftOutlined/>} onClick={()=> {props.onDismiss()}} style={{marginTop:'-6px', fontSize:'12px'}}><b>Edit Post</b></Button>
                    {props.post.mediaLink.type == "VIDEO" ?
                        <div className = "player-wrapper">
                            <ReactPlayer url={props.post.mediaLink.url}
                                playing={false} 
                                width='100%' 
                                height='100%'
                                controls={true}
                                style={{}}
                                alt={"Video of source " + props.post.mediaLink.url + " titled: " + props.post.title}/>
                        </div>
                        :
                        <Row justify ={"center"}>
                            <Col span={24} style={{textAlign:'center'}}>
                                <img src={props.post.mediaLink.url}
                                    style={{objectFit:'contain', width: 'auto', height:'auto', maxWidth:'100%', maxHeight:'60vh'}}
                                    alt={"Image of source " + props.post.mediaLink.url + " titled: " + props.post.title}/>
                            </Col>
                        </Row>
                    }
                <Divider>
                    <Tag icon={<CalendarOutlined/>}>{props.post.year}</Tag> 
                </Divider>
                <Row style={{marginBottom:'5px'}}>
                    <Col span={22} style={{fontSize:'24px'}}><b>{props.post.title}</b></Col>
                </Row>
                <Divider/>
                <Row style={{marginBottom:'5px'}}>
                    <Col span={24}>{props.post.history}</Col> 
                </Row>
                <Divider/>
                <Row>
                    <Col span={12}>
                        <Avatar size={20}style={{fontSize:'10px', color: '#f56a00', backgroundColor:'#fde3cf', marginRight:'5px'}}>
                            <UserOutlined/>
                        </Avatar>
                        <span>Uploaded By: </span>
                        <i>{props.post.uploaderDisplayName}</i>                   
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default PreviewPost;
