import React from 'react';

const ContactInfo = (props: any) => {

    return (
        <div style={{width:'100%', textAlign:'center'}}>
            <p style={{fontSize:'14px'}}>
                Email: <span style={{marginLeft:'6px', fontSize:'12px'}}>hahrchives.contact@gmail.com </span>          
            </p>
        </div>
    );
}

export default ContactInfo;