import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Row } from 'antd'
import { isLoaded, isEmpty, useFirestore } from 'react-redux-firebase';
import {useHistory} from 'react-router-dom';
import { DEVICES } from '../../../Consts';
import AccountDetails from '../Content/AccountDetails';
import MobileAccountDetails from '../Content/MobileAccountDetails';

const MyAccountPage = (props: any) => {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const auth = useSelector((state: any) => state.firebase.auth);
    const windowState = useSelector((state: any) => state.window);
    const [userRole, setUserRole] = useState('User');
    const history = useHistory();

    useEffect(() => {
        if(isEmpty(auth)) {
            history.push('/');
        }
    },[auth]);

    useEffect(() => {
        if(!isEmpty(auth)) {
            firestore.collection('users').doc(auth.uid).get().then((doc) => {
                const userData = doc.data();
                if(userData?.roles?.includes('Moderator')) {
                    setUserRole('Moderator');
                }
            }).catch((err) => {
                console.log("ERROR??", err)
            });
        }

        // for history but maybe a better way?
        //dispatch(setCategory('UserPosts'));
        /*return () => {
            // Component Unmount
            dispatch(setCategory("Recent"));
        }*/
    }, [])

    return (
        <>  {isLoaded(auth) && !isEmpty(auth) &&
            <Row justify='center'>
                { (windowState.device) > DEVICES.Tablet ? // horizontal orientation or vertical
                    <AccountDetails userRole={userRole} /> :
                    <MobileAccountDetails userRole={userRole} />           
                }
            </Row>}
        </>
    );
}
export default MyAccountPage;
