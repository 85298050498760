import React, {useEffect, useState} from 'react';
import {Row, Button, Tooltip} from 'antd';
import { isEmpty, useFirestore } from 'react-redux-firebase'
import {CaretUpFilled, CaretDownFilled} from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux';
import Utils from '../../../Utils';

interface upvoteDownvoteProps {
    postId: string;
    upvotes: number;
}

const UpvoteDownvote = (props: upvoteDownvoteProps) => {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.firebase.auth);
    const [numUpvotes, setUpvotes] = useState(props.upvotes);
    const [isUpvoted, setIsUpvoted] = useState(false);
    const [isDownvoted, setIsDownvoted] = useState(false);

    const onUpvoteClick = (e: any) => {
        if(!isEmpty(auth)) {
            firestore.collection('users').doc(auth.uid).get().then((doc) => {
                let data = doc.data();
                let isUpvoted = data?.upvotedPosts?.includes(props.postId);
                //Check isDownvoted, + 2?
                if(!isUpvoted) {
                    let batch = firestore.batch();
                    let postsRef = firestore.collection('posts').doc(props.postId);
                    batch.update(postsRef, {upvotes: firestore.FieldValue.increment(1)});
                    let usersRef = firestore.collection('users').doc(auth.uid);
                    batch.update(usersRef, {downvotedPosts: firestore.FieldValue.arrayRemove(props.postId)});
                    batch.update(usersRef, {upvotedPosts: firestore.FieldValue.arrayUnion(props.postId)});
                    batch.commit().then((success) => {
                        setIsUpvoted(true);
                        setIsDownvoted(false);
                    }).catch((error) => {
        
                    });
                }
            })
        } else {
            Utils.showLoginMessage(dispatch);
        }
        e.stopPropagation();
    };

    const onDownvoteClick = (e: any) => {
        if(!isEmpty(auth)) {
            firestore.collection('users').doc(auth.uid).get().then((doc) => {
                let data = doc.data();
                let isDownvoted = data?.downvotedPosts?.includes(props.postId);
                if(!isDownvoted) {
                    let batch = firestore.batch();
                    let postsRef = firestore.collection('posts').doc(props.postId);
                    batch.update(postsRef, {upvotes: firestore.FieldValue.increment(-1)});
                    let usersRef = firestore.collection('users').doc(auth.uid);
                    batch.update(usersRef, {upvotedPosts: firestore.FieldValue.arrayRemove(props.postId)});
                    batch.update(usersRef, {downvotedPosts: firestore.FieldValue.arrayUnion(props.postId)});
                    batch.commit().then((success) => {
                        setIsDownvoted(true);
                        setIsUpvoted(false);
                    }).catch((error) => {
        
                    });
                }
            })

        } else {
            Utils.showLoginMessage(dispatch);
        }
        e.stopPropagation();
    };

    useEffect(() => {
        if(!isEmpty(auth)) {
            firestore.collection('users').doc(auth.uid).get().then((doc) => {
                let data = doc.data();
                let isDownvoted = data?.downvotedPosts?.includes(props.postId);
                let isUpvoted = data?.upvotedPosts?.includes(props.postId);
                setIsUpvoted(isUpvoted);
                setIsDownvoted(isDownvoted);
            });            
        } else {
            setIsDownvoted(false)
            setIsUpvoted(false);
        }
        if(props.postId != "") {
            const unsubscribe = 
            firestore.collection('posts').doc(props.postId).onSnapshot((doc) => {
                //console.log(doc.data());
                const data = doc.data();
                if(data != undefined && data.upvotes >= 0) {
                    setUpvotes(data.upvotes);
                }
            });
            return () => {
                unsubscribe();
            }
        }
    },[auth]);

    return (
        <div style={{fontSize:'15px'}}>
            <Row justify = "center">
                <Tooltip placement="right" title={"Upvote"} arrowPointAtCenter>
                    <Button type="text" onClick={onUpvoteClick} icon={<CaretUpFilled style={{fontSize:'18px', color: isUpvoted ? 'purple' : 'rgba(175, 175, 255, 1)'}}/>}></Button>
                </Tooltip>
            </Row>
            <Row justify = "center" style={{marginTop:'-10px', marginBottom:'-10px'}}>    
                {numUpvotes}
            </Row>
            <Row justify = "center">
                <Tooltip placement="right" title={"Downvote"} arrowPointAtCenter>
                    <Button type="text" onClick={onDownvoteClick} icon={<CaretDownFilled style={{fontSize:'18px', color: isDownvoted ? 'purple' : 'rgba(175, 175, 255, 1)'}}/>}></Button>
                </Tooltip>           
            </Row>
        </div>
    );
}

export default UpvoteDownvote;
