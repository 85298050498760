import React from 'react';
import {Row, Col, Space} from 'antd';
import LoginButton from '../LoginButton';
import SignupButton from '../SignupButton';
import UserDropDown from '../../Menus/UserDropDown';
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { DEVICES } from '../../../Consts';

interface UserControlProps {
}

const UserControls = (props: UserControlProps) => {
  const auth = useSelector((state: any) => state.firebase.auth);
  const windowState = useSelector((state: any) => state.window);

  const getUserControls = () => {
    if(isLoaded(auth) && windowState.device > DEVICES.Tablet) {
      if(!isEmpty(auth)) {
        return (<Col span = {16}>
                  <i style={{color:'white'}}>{auth.displayName}</i>
                </Col> );
      } else {
        return(<>
                <Col span = {8}>
                  <LoginButton/>
                </Col>
                <Col span = {8}>
                  <SignupButton/>
                </Col>
              </>);
      }
    } else {
      return (<></>)
    }
  }

  return (
    <Row justify = "end">
        <Space>
            {getUserControls()}
            <Col span = {8}>
              <UserDropDown/>
            </Col> 
        </Space>
    </Row>    
  );
}

export default UserControls;
