import React, {useState} from 'react';
import {Input, Form, Button, Row, Col, Checkbox} from 'antd';
import { useFirestore, isEmpty } from "react-redux-firebase";
import { useSelector } from 'react-redux';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

const {TextArea} = Input;

interface FlagPostFormProps {
    postId: string,
    onSubmit: Function;
}

const reportReasonMap: Map<number, string> = 
        new Map([[0, "Post isnt funny"],
                 [1, "Post contains inappropriate content"],
                 [2, "The year is wrong"]]);

const FlagPostForm = (props: FlagPostFormProps) => {
    const auth = useSelector((state: any) => state.firebase.auth);
    const firestore = useFirestore();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [checkboxStates, setCheckBoxStates] = useState([false,false,false]);

    const formFilled = (commentText: string) : boolean => {
        if(checkboxStates[0] == false && 
           checkboxStates[1] == false &&
           checkboxStates[2] == false &&
           commentText == "") {
               return false;
           }
        return true;
    }

    const onFinish = (values: any) => {
        let additionalComment: string = values.comment == undefined ? "" : values.comment;
        if(!isEmpty(auth) && formFilled(additionalComment)) {
            setLoading(true);
            const flaggedPost = {
                userId: auth.uid,
                postId: props.postId,
                notFunny: checkboxStates[0],
                innapropriate: checkboxStates[1],
                wrongYear: checkboxStates[2],
                comment: additionalComment,
            }
            firestore.collection('flaggedPosts').where('userId', '==', auth.uid).get().then((query)=> {
                console.log("GOT QUERY");
                if(query.empty) {
                    console.log("Got empty")
                    firestore.collection('flaggedPosts').add(flaggedPost).then(succes => {    
                        setLoading(false);
                        props.onSubmit();
                    }).catch(error => {
                        console.log("Error flag post ", error);
                        setLoading(false);
                        props.onSubmit();
                    });
                } else {
                    setErrorMessage('* Our records show you have already flagged this post! It is currently in review.')
                    setLoading(false);
                }
            }).catch(error => {
                console.log(error);
                setLoading(false);
            })
        }
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onCheckboxChange = (key: any) => {
        let states = checkboxStates;
        states[key] = !states[key];
        setCheckBoxStates(states);
    };

    return (
        <Form
            name="report"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            >
            <Row style={{paddingBottom:'12px', fontSize:'16px'}} justify={"center"}>
                <Col>
                <ExclamationCircleTwoTone twoToneColor={"red"}></ExclamationCircleTwoTone> Report...
                </Col>
            </Row>
            <Row style={{paddingTop:'12px'}}>
                <Col>* <i>Post isnt funny:</i><Checkbox onChange={() => onCheckboxChange(0)} style={{paddingLeft:'12px'}}></Checkbox></Col>
            </Row>
            <Row style={{paddingTop:'12px'}}>
                <Col>* <i>Post contains inappropriate content:</i><Checkbox onChange={() => onCheckboxChange(1)} style={{paddingLeft:'12px'}}></Checkbox></Col>
            </Row>
            <Row style={{paddingTop:'12px'}}>
                <Col>* <i>The year is wrong:</i><Checkbox onChange={() => onCheckboxChange(2)} style={{paddingLeft:'12px'}}></Checkbox></Col>
            </Row>
            <Row style={{paddingTop:'12px', textAlign:'center'}}>
            <Form.Item
                style={{width:'100%'}}
                name="comment"
                rules={[
                    { max: 250, message: 'Must be 250 characters or less!'}
                ]}
            >
                <TextArea placeholder={"Additional comments (optional)"}></TextArea>
            </Form.Item>
            </Row>
            <Row style={{paddingTop:'12px'}} justify={"center"}>
                <Button type="primary" loading={loading} block={true} htmlType="submit">
                    Submit
                </Button>
                <br/>
                <span style={{color:'red', fontSize:'10px'}}><i>{errorMessage}</i></span>
            </Row>
        </Form>     
    );
}

export default FlagPostForm;