import React from 'react'
import { triggerLoginModal } from './Actions/Modal'
import { message } from 'antd';
import ReactPlayer from 'react-player';
import { DEVICES } from './Consts';
import firebase from 'firebase/compat/app';

export default class Utils {
    static docToIPost = (doc: any, rank: number) : IPost => {
        let data = doc.data();
        let post: IPost = {
            id: doc.id,
            rank: rank,
            likes: data.likes,
            upvotes: data.upvotes == undefined ? 0 : data.upvotes,
            mediaLink: {
                type: data.urlType,
                url: data.url
            },
            title: data.title,
            history: data.history == undefined ? "data.history" : data.history,
            comments: data.comments == undefined ? 0 : data.comments,
            uploaderDisplayName: data.uploaderDisplayName,
            userId : data.userId,
            year : data.year,
            created : data.created,
        };
        return post;
    };

    static emptyIPost = () : IPost => {
        let post: IPost = {
            id: "",
            rank: 0,
            likes: 0,
            upvotes: 0,
            mediaLink: {
                type: "",
                url: ""
            },
            title: "",
            history: "",
            comments: 0,
            uploaderDisplayName: "",
            userId : "",
            year : 0,
            created : 0,
        };
        return post;
    };

    static docToIComment = (doc: any) : IComment => {
        let data = doc.data();
        let comment: IComment = {
            commentText: data.comment,
            displayName: data.displayName,
        };
        return comment;
    };

    static getMediaTypeString = (mediaType: string) : string => {
        if(mediaType == "IMAGE") {
            return "images";
        } else if(mediaType == "VIDEO") {
            return "vidoes";
        } else {
            return "posts";
        }
    }

    static showLoginMessage = (dispatch: Function) => {
        // Cant instantiate dispatch here.
        const onLoginClick = () => {
            dispatch(triggerLoginModal());
        };
        return (message.info(<span>Must be signed in! <a onClick={onLoginClick}>Login</a></span>))
    }; 

    static getDevice = (windowWidth: number) : number => {
        if(windowWidth <= 320) {
            return DEVICES.MobileSm;
        } else if(windowWidth <= 375) {
            return DEVICES.MobileMd;
        } else if(windowWidth <= 425) {
            return DEVICES.MobileLg;
        } else if(windowWidth <= 600) {
            return DEVICES.TabletSm;
        } else if(windowWidth <= 768) {
            return DEVICES.Tablet;
        } else if(windowWidth <= 1024) {
            return DEVICES.Laptop;
        } else if(windowWidth <= 1440) {
            return DEVICES.LaptopLg;
        } else if(windowWidth <= 2560) {
            return DEVICES.Monitor4K;
        } else {
            return DEVICES.UltraWide;
        }
    }

    static getFirebaseTimeStamp = () : any => {
        const time: number = firebase.firestore.Timestamp.fromDate(new Date()).seconds;
        return time;
    }

    static determineMediaType = (url: string, callback: any) => {
        let img = new Image();
        img.src = url;        
        img.onload = () => {
            callback("IMAGE");
        }
        img.onerror = () => {
            if(ReactPlayer.canPlay(url)) {
                callback("VIDEO");
            } else {
                callback("INVALID");
            }
        }
    }

    static createSearchTerms = (title: string): Array<string> => {
        let terms: Array<string> = new Array<string>();
        // Push substrings of main title, in order.
        for (let i = 1; i < title.length + 1; ++i) {
            terms.push(title.substring(0, i));
        }
        // Push substrings of individual words. 
        let split: Array<string> = title.split(' ');
        let dups: Set<String> = new Set<string>();
        for(let word of split) {
            if(!dups.has(word)) {
                dups.add(word);
                for (let i = 1; i < word.length + 1; ++i) {
                    let substr: string = word.substring(0, i);
                    if(!terms.includes(substr)) {
                        terms.push(substr);
                    }
                }
            }
        }
        return terms;
    };

    static formatFirebaseError = (errorMsg: string): string => {
        return errorMsg.replace("Firebase: ", "");
    }
}