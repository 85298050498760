import React from 'react';
import {Button} from 'antd';
import { useDispatch } from 'react-redux';
import {triggerSignupModal} from '../../Actions/Modal';

const SignupButton = (props : any) => {
  const dispatch = useDispatch();

  const signupButtonClick = () => {
    dispatch(triggerSignupModal());
  }

  return (
      <div>
        <Button onClick={signupButtonClick}>Signup</Button>
      </div>
  );

}
export default SignupButton;
