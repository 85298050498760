import React from 'react';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {setMobileSideMenuVisible} from '../../Actions/SideMenu';
import PostListNavigationMenu from './PostListNavigationMenu';

interface MobileSideDrawerProps {
}

const MobileSideDrawer = (props: MobileSideDrawerProps) => {
    const dispatch = useDispatch();
    const sideMenu = useSelector((state:any) => state.sideMenu);

    const onCloseClick = () => {
        dispatch(setMobileSideMenuVisible(!sideMenu.visible));
    }

    return (
        <Drawer visible={sideMenu.visible} closable={true} closeIcon={<MenuOutlined/>} onClose={onCloseClick} placement={'left'}>
            <PostListNavigationMenu/>
        </Drawer>
    );
}

export default MobileSideDrawer;