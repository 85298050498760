import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Row, Col, Button, Card} from 'antd'
import { LeftOutlined } from '@ant-design/icons';
import {isEmpty } from 'react-redux-firebase';
import {useHistory, useLocation} from 'react-router-dom';
import CreatePostForm from '../../Input/CreatePostForm';
import { DEVICES } from '../../../Consts';


interface LocationState {
    defaultYear : number
}

const CreatePostPage = (props: any) => {
    const location = useLocation<LocationState>();
    const defaultYear = location.state.defaultYear;
    const auth = useSelector((state: any) => state.firebase.auth);
    const history = useHistory();
    const windowState = useSelector((state: any) => state.window);

// Empty Favorites -> MyAccount bug.
    useEffect(() => {
        if(isEmpty(auth)) {
            history.push('/');
        }
    },[auth]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <Row justify="center" /*align="middle"*/ style={{}}>
            <Col span={windowState.device <= DEVICES.Tablet ? 22 : 10} style={{maxWidth:'600px'}}>
                <Card style={{marginBottom:'24px', marginTop:'24px'}}>
                    <CreatePostForm visible={true} defaultYear={defaultYear}/>
                </Card>
                <Row style={{marginTop:'24px', marginBottom:'24px'}}>
                    <Col span={6}>
                        <Button type="text" size="small" onClick={()=> {history.push('/');}} icon={<LeftOutlined/>} style={{marginTop:'-6px', fontSize:'12px'}}><b>List</b></Button>
                    </Col>
                </Row>
            </Col>
        </Row>
   );
}
export default CreatePostPage;
