import React from 'react';
import {Button} from 'antd';
import {MenuOutlined} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import {setMobileSideMenuVisible} from '../../Actions/SideMenu';
import MobileBackButton from './MobileBackButton'; // Clean this up, shouldnt be here. 'MobileControls'?
import { useLocation } from 'react-router-dom';

interface MobileSideMenuButtonProps {
}

const MobileSideMenuButton = (props: MobileSideMenuButtonProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const sideMenu = useSelector((state:any) => state.sideMenu);

  const onClick = (e: any) => {
      dispatch(setMobileSideMenuVisible(!sideMenu.visible));
  }

  return (
      <>
        { location.pathname != "/" ?
          <MobileBackButton/> :
          <Button type="link" size={"large"} onClick={onClick} icon={<MenuOutlined style={{color:'white'}}/>}></Button>
        }
        </>
  );

}
export default MobileSideMenuButton;
