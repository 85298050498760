import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import {COLORS, YEAR_MIN, YEAR_MAX } from '../../Consts';

interface YearSelectorProps {
    onYearChange : Function;
    enabled : boolean;
    selected : number;
}

// list of items
const list: any = [];
for(let i = YEAR_MIN; i<=YEAR_MAX; ++i) {
    list.push({year: i});
}

// One item component
// selected prop will be passed
const MenuItem = (props: {text: any, selected: any, enabled: any}) => {
    let color = props.enabled ? (props.selected ? COLORS.selectedPrimary : 'rgba(120,120,120,255)') : 'rgba(220,220,220,255)';
    let backgroundColor = props.selected && props.enabled ? '#e6f7ff' : '';
    let border = props.selected && props.enabled ? '1px solid ' + COLORS.selectedPrimary : '';
    let fontSize = props.selected ? '13px' : '12px';
    let cursor = props.enabled ? 'pointer' : ''
    return (<div style={{paddingLeft:'10px', paddingRight:'10px', color:color, backgroundColor:backgroundColor, borderBottom:border, cursor:cursor, fontSize:fontSize}}>{props.text}</div>);
  };
   
// All items component
// Important! add unique key
export const Menu = (list: any, selected: any, enabled: any) => {
    let itemArray:any = [];
    list.map((el: any) => {
        const name = el.year.toString();
        itemArray.push(<MenuItem text={name} key={name} selected={selected} enabled={enabled}/>);
    });
    return itemArray;
}

const YearSelector = (props: YearSelectorProps) => {
    const menu: any = Menu(list, props.selected, props.enabled);

    const onSelect = (key: any) => {
        if(props.enabled) {
            props.onYearChange(parseInt(key));   
        }
    }

    return (
        <div style={{width:'100%'}}>
            <ScrollMenu
                data={menu}
                arrowLeft={<LeftOutlined style={{color:'rgba(120,120,120,255)', cursor: props.enabled ? 'pointer' : '', paddingLeft:'5px', paddingRight:'5px'}}/>}
                arrowRight={<RightOutlined style={{color:'rgba(120,120,120,255)', cursor: props.enabled ? 'pointer' : '', paddingRight:'5px'}}/>}
                selected={props.selected.toString()}
                onSelect={onSelect}
                scrollToSelected={true}
                alignOnResize={true}
                itemStyle={{ outline: 'none', pointerEvents: props.enabled ? 'auto' : 'none' }}
                innerWrapperStyle={{  backgroundColor: props.enabled ? '' : 'rgba(250,250,250,255)'}}
                />
        </div>
    );
}

export default YearSelector;