import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {Layout} from 'antd';
import PostListPage from './Page/PostListPage';
import PostPage from './Page/PostPage';
import MyAccountPage from './Page/MyAccountPage'
import ModalBase from '../Modals/ModalBase';
import CreatePostPage from './Page/CreatePostPage';

const {Content} = Layout;

const AppContent = (props: any) => {
    const history = useHistory();

    useEffect(() => {
        history.listen((location: any, action: any) => {
                console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
            console.log(`The last navigation action was ${action}`)
        })
    }, []);
    return (
        <Content className="ContentContainer">
            <Switch>
                <Route path='/' exact component={PostListPage} />
                {/*<Route path='/Recent' exact component={PostListPage} />
                <Route path='/Favorites/:id' exact component={PostListPage} />*/}
                <Route path='/create/:id' component={CreatePostPage} />
                <Route path='/post/:id' component={PostPage} />
                <Route path='/account/:id' component={MyAccountPage} />
            </Switch>
            <ModalBase/>
        </Content>
    );
}
export default AppContent;
