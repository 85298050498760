import React from 'react';
import {Menu, Dropdown, Button} from 'antd';
import {UserOutlined, LoginOutlined, LogoutOutlined, SettingOutlined} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase';
import {useHistory} from 'react-router-dom';
import {triggerLoginModal} from '../../Actions/Modal';
import { DEVICES } from '../../Consts';

const UserDropDown = (props: any) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const firebase = useFirebase();
  const auth = useSelector((state: any) => state.firebase.auth);
  const windowState = useSelector((state: any) => state.window);

  const onLogoutClick = () => {
    firebase.logout();
  }

  const onMyAccountClick = () => {
    if(isLoaded(auth) && !isEmpty(auth)) {
      let myAccountPath = "/account/" + auth.uid;
      history.push(myAccountPath, {});
    }
  }

  const onLoginSignupClick = () => {
    dispatch(triggerLoginModal());
  }

  const menu = (
    (isLoaded(auth) && !isEmpty(auth)) ?
    <Menu>
      {(windowState.device <= DEVICES.Tablet) &&
      <Menu.Item style={{textAlign:'center', borderBottom:'1px solid lightGray'}}>
        <span style={{marginTop:'5px', marginBottom:'5px'}}><i>{auth.displayName}</i></span>
      </Menu.Item>}
      <Menu.Item style={{borderBottom:'1px solid lightGray'}}>
        <a onClick={onMyAccountClick}>
          <SettingOutlined style={{marginRight:'10px'}}/> My Account
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={onLogoutClick}>
          <LogoutOutlined style={{marginRight:'10px'}}/> Logout
        </a>
      </Menu.Item>
    </Menu> :
    <Menu>
      <Menu.Item>
        <a onClick={onLoginSignupClick}>
          <LoginOutlined style={{marginRight:'10px'}}/> Login / Signup
        </a>
      </Menu.Item>   
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
        <Button type = "ghost" icon = {<UserOutlined style={{color:'white'}}/>}></Button>
    </Dropdown>  
  );
}

export default UserDropDown;
