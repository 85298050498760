import { ADD_REMOVE_POST } from '../Actions/List';

const initialState = {
  reload: 0
};

function listReducer(state = initialState, action: any) {
    switch (action.type) {
        case ADD_REMOVE_POST:
            return {
                reload: state.reload + 1
            }
        default : break;
    }
   return state;
}

export default listReducer;