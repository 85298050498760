import React from 'react';
import { Card, } from 'antd';
import PostListNavigationMenu from './PostListNavigationMenu';

interface FloatingListMenuProps {
}

const FloatingListMenu = (props: FloatingListMenuProps) => {

    return (
        <Card>
            <PostListNavigationMenu/>
        </Card>
    );
}

export default FloatingListMenu;