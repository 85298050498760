import { MODAL_TYPE } from '../Actions/Modal';

const initialState = {
  visible: false,
  stacked: false,
  title: '',
  props: new Map<string, any>(),
};

function ModalReducer(state = initialState, action: any) {
  switch (action.type) {
        case MODAL_TYPE.LOGIN:
            return {
                type: MODAL_TYPE.LOGIN,
                visible: action.visible,
                stacked: false,
                title: 'Login',
                props: {}
            };
        case MODAL_TYPE.SIGNUP:
            return {
                type: MODAL_TYPE.SIGNUP,
                visible: action.visible,
                stacked: false,
                title: 'Signup',
                props: {}
            };
        case MODAL_TYPE.RESET_PASSWORD:
            return {
                type: MODAL_TYPE.RESET_PASSWORD,
                visible: action.visible,
                stacked: false,
                title: 'Reset Password',
                props: {}
            };
        case MODAL_TYPE.EDIT_POST:
            return {
                type: MODAL_TYPE.EDIT_POST,
                visible: action.visible,
                stacked: false,
                title: 'Edit Post',
                props: action.props,
            };
        case MODAL_TYPE.FLAG_POST:
            return {
                type: MODAL_TYPE.FLAG_POST,
                visible: action.visible,
                stacked: false,
                title: 'Flag Post',
                props: action.props,
            };
        case MODAL_TYPE.ABOUT:
            return {
                type: MODAL_TYPE.ABOUT,
                visible: action.visible,
                stacked: false,
                title: 'About',
            };
        case MODAL_TYPE.RULES:
            return {
                type: MODAL_TYPE.RULES,
                visible: action.visible,
                stacked: false,
                title: 'Rules',
            };
        case MODAL_TYPE.CONTACT:
            return {
                type: MODAL_TYPE.CONTACT,
                visible: action.visible,
                stacked: false,
                title: 'Contact',
            };
        case MODAL_TYPE.TERMS:
            return {
                type: MODAL_TYPE.TERMS,
                visible: action.visible,
                stacked: action.stacked,
                title: 'Terms of Use',
            };
        case MODAL_TYPE.PRIVACY:
            return {
                type: MODAL_TYPE.PRIVACY,
                visible: action.visible,
                stacked: action.stacked,
                title: 'Privacy Policy',
            };
        case MODAL_TYPE.CLOSE:
            return {
                type: MODAL_TYPE.CLOSE,
                visible: action.visible,
                stacked: false,
                title: '',
                props: {}
            };
        default : break;
  }
  return state;
}

export default ModalReducer;