import { PostFilter } from '../Actions/PostFilter';
import { YEAR_MAX } from '../Consts';

const initialState = {
    mediaType: 'ALL',
    category: 'Recent',
    year: YEAR_MAX,
    allTime: true,
    searchTerm: '',
};

function postFilterReducer(state = initialState, action: any) {
  switch (action.type) {
        case PostFilter.MEDIA_TYPE:
              console.log(action.mediaType);
            return {
                mediaType: action.mediaType,
                category: state.category,
                year: state.year,
                allTime: state.allTime,
                searchTerm: '',
            };
        case PostFilter.CATEGORY:
              console.log(action.category);
            return {
                mediaType: state.mediaType,
                category: action.category,
                year: state.year,
                allTime: state.allTime,
                searchTerm: '',
            };
        case PostFilter.YEAR:
              console.log(action.year);
            return {
                mediaType: state.mediaType,
                category: state.category,
                year: action.year,
                allTime: state.allTime,
                searchTerm: '',
            };
        case PostFilter.ALL_TIME:
              console.log(action.allTime);
            return {
                mediaType: state.mediaType,
                category: state.category,
                year: state.year,
                allTime: action.allTime,
                searchTerm: '',
            };
        case PostFilter.SEARCH:
              console.log(action.searchTerm);
            return {
                mediaType: state.mediaType,
                category: state.category,
                year: state.year,
                allTime: state.allTime,
                searchTerm: action.searchTerm,
            };
        case PostFilter.RESET:
            return initialState;
        default : break;
  }
  return state;
}

export default postFilterReducer;