import React, {useState, useEffect} from 'react';
import {Input, Form, Checkbox, Button} from 'antd';
import { MailOutlined, LockOutlined} from '@ant-design/icons';
import { useFirebase } from "react-redux-firebase";
import { useDispatch } from 'react-redux';
import {triggerResetPassword, triggerSignupModal} from '../../Actions/Modal'
import Utils from '../../Utils';

interface LoginFormProps {
    onSubmit: Function;
    visible: boolean;
}

const LoginForm = (props: LoginFormProps) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const login = (email: string, username: string, password: string) => {
        setLoading(true);
        firebase
        .login(
            { email, password }
          ).then((user) => {
            console.log("Then login");
            form.resetFields();
            props.onSubmit();
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            setErrorMessage('* ' + Utils.formatFirebaseError(error.message));
            console.log("ERROR LOGIN", error);
        });
    }

    const onFinish = (values: any) => {
        login(values.email, values.username, values.password);
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onSignupLinkClick = () => {
        dispatch(triggerSignupModal());
    }

    const onForgotPasswordClick = () => {
        dispatch(triggerResetPassword());
    }

    useEffect(() => {
        // Unmounted component bug?
        form.resetFields();
    },[props.visible]);

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            >
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: 'Please input your email!'},
                ]}
            >
                <Input prefix={<MailOutlined/>} placeholder={"Email"}/>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    { required: true, message: 'Please input your password!'},
                ]}
            >
                <Input.Password prefix={<LockOutlined/>} placeholder={"Password"}/>
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me{/*not checked bc forgot password*/}</Checkbox>
                <a style={{fontSize:'12px', float:'right'}} onClick={onForgotPasswordClick}><i><b>Forgot password?</b></i></a>
            </Form.Item>

            <Form.Item>
                <Button type="primary" block={true} loading={loading} htmlType="submit">
                    Submit
                </Button>
                <br/>
                <span style={{color:'red', fontSize:'10px'}}><i>{errorMessage}</i></span>
            </Form.Item>

            <div style={{width:'100%', textAlign:'center', paddingTop:'12px', fontSize:'12px'}}>
                <span>
                    <b>Dont have an account? <a onClick={onSignupLinkClick}><i>Sign up!</i></a></b>
                </span>
            </div>

        </Form>    
    );
}

export default LoginForm;
