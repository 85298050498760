import React, {useState, useEffect} from 'react';
import {Row, Col, Divider} from 'antd';
import FloatingListMenu from '../../Menus/FloatingListMenu';
import SiteInfoMenu from '../../Menus/SiteInfoMenu';
import PostList from '../../Lists/PostList';
import MobileSideDrawer from '../../Menus/MobileSideDrawer';
import FixedAdSpace from '../../Info/FixedAdSpace';
import BackToTop from '../../Buttons/BackToTop';
import { useSelector } from 'react-redux';
import { DEVICES, POST_LIST_TYPE } from '../../../Consts';

const PostListPage = (props: any) => {
    const [leftSpan, setLeftSpan] = useState(8);
    const [midSpan, setMidSpan] = useState(8);
    const [rightSpan, setRightSpan] = useState(8);
    const windowState = useSelector((state: any) => state.window);

    useEffect(() => {
        let leftRightSpan = 0;
        let midSpan = 0;
        if(windowState.device <= DEVICES.MobileLg) {
            leftRightSpan = 0;
            midSpan = 24;
        } else if(windowState.device <= DEVICES.Tablet) {
            leftRightSpan = 3;
            midSpan = 18;
        } else {
            leftRightSpan = 8;
            midSpan = 8;
        }
        setLeftSpan(leftRightSpan);
        setMidSpan(midSpan);
        setRightSpan(leftRightSpan);
    }, [windowState]);

    return (
        <div>
            {windowState.device <= DEVICES.Tablet &&
                <Row justify="center" style={{backgroundColor:'rgba(225,225,225,1)'}}>
                        <SiteInfoMenu/>
                        <Divider style={{margin:'0'}}/>
                </Row> 
            }
            <Row justify='center'>
                <Col span={24} style={{maxWidth:'1500px'}}>
                    <Row>
                        <Col span={leftSpan} className="stickyCol">
                            <Row justify="end" style={{width:'100%'}}>
                                {windowState.device <= DEVICES.Tablet ? 
                                              <MobileSideDrawer/>
                                            : 
                                            <div className="sticky stickyContent" style={{marginRight:'60px'}}>
                                                <FloatingListMenu/>
                                            </div>}
                            </Row>
                        </Col>
                        <Col span={midSpan}>
                            <Row justify="center">
                                <PostList postListType={POST_LIST_TYPE.Filtered_Query}/>
                            </Row>
                        </Col>
                        <Col span={rightSpan} className="stickyCol">
                            <Row justify="start">
                                {windowState.device > DEVICES.Tablet && 
                                    <div className="sticky stickyContent" style={{marginLeft:'60px'}}>
                                        <FixedAdSpace/>
                                        <SiteInfoMenu/>
                                    </div>
                                }
                            </Row>
                        </Col>
                    </Row>
                    <BackToTop/>
                </Col>
            </Row>
        </div>
    );   
}
export default PostListPage;
