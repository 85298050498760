import React from 'react';
import { Divider } from 'antd';

const RulesInfo = (props: any) => {

    return (
        <div style={{fontSize:'12px'}}>
            <span style={{fontSize:'14px', fontWeight:'bold'}}>General</span><br/>
            <div style={{paddingLeft:'12px'}}>
                - All content posted to the site must be <u>funny</u> <i>(invoking laughter or amusement, for the majority of individuals) </i>  
                and <u>viral</u> <i>(circulated or viewed millions of times)</i>. 
            </div>
            <Divider style={{marginTop:'6px', marginBottom:'6px'}}/>
            <span style={{fontSize:'14px', fontWeight:'bold'}}>Content Submission</span>
            <ul>
                <li> <b>Links (video): </b>Youtube, Facebook, Vimeo, Dailymotion, Twitch </li>
                <li> <b>Links (image): </b>Image hosting is not currently supported. Please use image source urls ending in .jpg, .png, .gif etc. </li>
                <li> <b>Title: </b> Short and succint, will be used for search. </li>
                <li> <b>History: </b> Be descriptive and share as much info as possible - what is the content about, what made it viral, where are the individual(s) now, 
                                      what historical events could have influenced it, what was the impact on society, etc. <i>The history of your post may be subject
                                      to outside revisions, as the site grows.</i></li>
                <li> <b>No </b> spamming </li>
                <li> <b>No </b> duplicates </li>
                <li> <b>No </b> sexual content or gore </li>
                <li> <b>No </b> unwarranted racism, sexism, transphobia</li>
                <li> <b>No </b> compilation videos </li>
                <li> <b>No </b> staged pranks or similarly unoriginal content </li>
            </ul>         
            <Divider style={{marginTop:'6px', marginBottom:'6px'}}/>
            <span style={{fontSize:'14px', fontWeight:'bold'}}>Issues</span>
            <ul>
                <li> NSFW youtube content cannot be linked </li>
            </ul>            
        </div>
    );
}

export default RulesInfo;