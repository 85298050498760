import React from 'react';
import {Button} from 'antd';
import {LeftOutlined} from '@ant-design/icons'
import { useHistory } from 'react-router-dom';

interface MobileBackButtonProps {
}

const MobileBackButton = (props: MobileBackButtonProps) => {
  const history = useHistory();

  const onClick = (e: any) => {
    history.goBack();
  }

  return (
      <>
        <Button type="link" size={"large"} onClick={onClick} icon={<LeftOutlined style={{color:'white'}}/>}></Button>
      </>
  );

}
export default MobileBackButton;
