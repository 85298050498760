export const PostFilter = {
    MEDIA_TYPE : 'MEDIA_TYPE',
    CATEGORY : 'CATEGORY',
    YEAR : 'YEAR',
    ALL_TIME : 'ALL_TIME',
    SEARCH : 'SEARCH',
    RESET : 'RESET'
}

const setMediaType = (mediaType: string) => {
    return { 
        type: PostFilter.MEDIA_TYPE, 
        mediaType
    };
}

const setCategory = (category: string) => {
    return { 
        type: PostFilter.CATEGORY, 
        category
    };
}

const setYear = (year: number) => {
    return { 
        type: PostFilter.YEAR, 
        year
    };
}

const setAllTime = (allTime: boolean) => {
    return { 
        type: PostFilter.ALL_TIME, 
        allTime
    };
}

const setSearchTerm = (searchTerm: string) => {
    return { 
        type: PostFilter.SEARCH, 
        searchTerm
    };
}

const resetPostFilter = () => {
    return {
        type: PostFilter.RESET
    };
}

export { setMediaType, setCategory, setYear, setAllTime, setSearchTerm, resetPostFilter };