import React, { useState } from 'react';
import {Row, Comment, Avatar, Tooltip} from 'antd';
import { DislikeOutlined, LikeOutlined, DislikeFilled, LikeFilled, UserOutlined} from '@ant-design/icons';

interface CommentProps {
  comment: IComment;
}

const PostComment = ( props: CommentProps ) => {
    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);
    const [action, setAction] = useState('');
    const [openReply, openReplyForm] = useState(false);
    
    const like = () => {
      setLikes(1);
      setDislikes(0);
      setAction('liked');
    };
  
    const dislike = () => {
      setLikes(0);
      setDislikes(1);
      setAction('disliked');
    };

    const onReply = () => {
      openReplyForm(true);
    };

    const onReplyCancel = () => {
      openReplyForm(false);
    };
  
    const actions = [
      <span key="comment-basic-like">
        <Tooltip title="Like">
          {React.createElement(action === 'liked' ? LikeFilled : LikeOutlined, {
            onClick: like,
          })}
        </Tooltip>
        <span className="comment-action">{likes}</span>
      </span>,
      <span key=' key="comment-basic-dislike"'>
        <Tooltip title="Dislike">
          {React.createElement(action === 'disliked' ? DislikeFilled : DislikeOutlined, {
            onClick: dislike,
          })}
        </Tooltip>
        <span className="comment-action">{dislikes}</span>
      </span>,
      <span key="comment-basic-reply-to" onClick={onReply}>Reply</span>,
    ];
          {/*actions={[actions]}*/}

    return (
        <Comment
          author={<a>{props.comment.displayName}</a>}
          avatar={
            <Avatar style={{ backgroundColor: 'LightBlue'}} icon={<UserOutlined style={{color: 'white'}}/>} />
          }
          content={
            <Row justify={"start"}>
              {props.comment.commentText}
            </Row>
          }>
            {/*<div style={{height:'1px', backgroundColor:'rgba(235,235,235,255)'}}/>
            openReply && <AddComment onCancelSubmit={onReplyCancel}/>
            {props.comment.replies.map((reply, index) => (
                    <PostComment comment = {reply} key={index}/>
                ))}*/}
      </Comment>
    );
};

export default PostComment;
