import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createStore } from "redux";
//import { configureStore } from "@reduxjs/toolkit";
import { createFirestoreInstance } from "redux-firestore";
import { rootReducer } from "./Reducers/AllReducers";

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

const firebaseConfig = {
  apiKey: "AIzaSyDfDfHsOlYFw4TM6ATYEipnY5c65L-XqA0",
  authDomain: "harchives-b264c.firebaseapp.com",
  databaseURL: "https://harchives-b264c.firebaseio.com",
  projectId: "harchives-b264c",
  storageBucket: "harchives-b264c.appspot.com",
  messagingSenderId: "102694958805",
  appId: "1:102694958805:web:ec5fe071e15068320f945c",
  measurementId: "G-5B4KZ2XF8F"
};

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

const app = firebase.initializeApp(firebaseConfig);
const db = getFirestore(app);

const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  //<React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();