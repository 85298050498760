import React, {useState, useEffect} from 'react';
import {List, Spin, ConfigProvider} from 'antd';
import PostListItem from './PostListItem';
import EmptyList from './EmptyList'
import PostListHeader from './PostListHeader';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase'
import { useSelector, useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller';
import Utils from '../../Utils';
import { triggerFooterVisible } from '../../Actions/Footer';
import { POST_LIST_TYPE } from '../../Consts';
import firebase from 'firebase/compat/app';

interface PostListProps {
    postListType: POST_LIST_TYPE;
}

const PAGE_SIZE: number = 15;

const PostList = (props: PostListProps) => {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.firebase.auth);
    const postFilterState = useSelector((state: any) => state.postFilter);
    const listState = useSelector((state: any) => state.list);
    const [query, setQuery] = useState(Array<IPost>());
    const [loading, setLoading] = useState(true);
    const [startAfterItem, setStartAfterItem] = useState(null);
    const [queryParams, setQueryParams] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [rank, setRank] = useState(1);

    const onLoadMore = () => {
        loadData(queryParams, false);
        console.log("LOAD MORE");
    };

    const initListQuery = () => {
        let initialQueryParams: any;
        if(props.postListType == POST_LIST_TYPE.Pending_Posts) {
            if(isLoaded(auth) && !isEmpty(auth)) {
                initialQueryParams = firestore.collection('pendingPosts').orderBy("created", "asc");
            } else { return; }
        } else if(props.postListType == POST_LIST_TYPE.User_Posts) {
            if(isLoaded(auth) && !isEmpty(auth)) {
                initialQueryParams = firestore.collection('posts').where("userId", "==", auth.uid).orderBy("created", "desc");
            } else { return; }
        } else if(postFilterState.searchTerm !== '') {
            // Search
            initialQueryParams = firestore.collection('posts').where("searchTerms", "array-contains", postFilterState.searchTerm.toLowerCase())
        } else {
            // Category
            if(postFilterState.category === "Favorites") {
                if(isLoaded(auth) && !isEmpty(auth)) {
                    initialQueryParams = firestore.collection('favoritePosts').where("userId", "==", auth.uid).orderBy("addedTimeStamp", "desc");
                } else { return;  }
            } else if(postFilterState.category === "Top") {
                initialQueryParams = firestore.collection('posts').orderBy("upvotes", "desc");
            } else if(postFilterState.category === "Recent") {
                initialQueryParams = firestore.collection('posts').orderBy("created", "desc");
            }
            // Year
            if(!postFilterState.allTime && postFilterState.category !== "UserPosts") {
                initialQueryParams = initialQueryParams.where("year","==",postFilterState.year);
            }
            // Media Type
            if(postFilterState.mediaType !== "ALL") {
                initialQueryParams = initialQueryParams.where("urlType", "==",postFilterState.mediaType);
            }
        }
        setQueryParams(initialQueryParams);
        loadData(initialQueryParams, true);
    }
    
    const loadData = (queryParams: any, reloadQuery: boolean) => {
        if(queryParams == undefined || queryParams == null) {
            console.log("UNDEFINED QUERY PARAM");
            return;
        }
        let params = queryParams;
        if(startAfterItem != null && !reloadQuery) {
            params = params.startAfter(startAfterItem);
        } else {
            //setHasMore(false);
            console.log("IS UNDEFEIND - startAfterItem");
        }
        let postQuery: Promise<any> | undefined = params != undefined ? params.limit(PAGE_SIZE).get() : undefined;
        if(postQuery != undefined) {
            setLoading(true);//Need fix when last item == PAGE_SIZE, shows loader.
            // Initialize to empty list if reloading, or add to current post list.
            let posts: Array<IPost> = reloadQuery ? new Array<IPost>() : query;
            postQuery.then(query => {
                console.log(query.docs.length);
                // Set start after, last doc.
                setStartAfterItem(query.docs[query.docs.length-1]);
                if(query.docs.length < PAGE_SIZE) {
                    // No more docs
                    setHasMore(false);
                    dispatch(triggerFooterVisible(true));
                }
                // Push posts to query
                let rankCount: number = reloadQuery ? 1 : rank; // CHECK THIS
                let isFavorites: boolean = postFilterState.category == "Favorites" && props.postListType == POST_LIST_TYPE.Filtered_Query;
                // Cant use foreach with async
                const promises = [];
                for(const doc of query.docs) {
                    if(isFavorites) {
                        // Get the post data from posts collection, add to promise array
                        let promise = firestore.collection('posts').doc(doc.data().postId).get().then((postDoc) => {
                            let post = Utils.docToIPost(postDoc, rankCount);
                            posts.push(post);
                            console.log("Favorite", postDoc.data());
                        }).catch((error)=> {
                            console.log(error);
                        });
                        promises.push(promise);
                    } else {
                        let post = Utils.docToIPost(doc, rankCount);
                        ++rankCount;
                        posts.push(post);
                    }
                }
                const setListLoadedState = () => {
                    setRank(rankCount)
                    setQuery(posts);
                    setLoading(false); 
                }
                if(isFavorites) {
                    Promise.all(promises).then(() => {
                        setListLoadedState();
                    }).catch(err => {
                        console.log(err);
                    })
                } else {
                    setListLoadedState();
                }
            })
        }
    }

    const reload = () => {
        window.scrollTo(0,0);
        dispatch(triggerFooterVisible(false));
        setHasMore(true);
        setQuery(new Array<IPost>());
        setStartAfterItem(null);
        initListQuery();
    }

    // No easy way to get which prop triggered useEffect, separate for now.
    useEffect(() => {
        console.log("USE EFFECT FROM MAIN LIST FILTER STATE or LIST STATE, RELOAD");
        reload();
    }, [postFilterState, listState.reload, props.postListType]);
    useEffect(() => {
        if(postFilterState.category == "Favorites") {
            console.log("USE EFFECT FROM MAIN LIST FAVORITES, RELOAD");
            reload();
        }
    }, [auth]);
    useEffect(() => {
        // Set footer visible on unmount.
        return () => {
            dispatch(triggerFooterVisible(true));
        };
      }, []);    

    return (
        <div className="ListBackground">
            <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={onLoadMore}
            hasMore={!loading && hasMore}
            useWindow={true}
            >
                <ConfigProvider renderEmpty={() => <EmptyList postListType={props.postListType} postFilterState={postFilterState}/>}>
                <List
                    header={<PostListHeader postListType={props.postListType} postFilterState={postFilterState}/> }
                    dataSource={query}
                    renderItem={item =><List.Item><PostListItem postListType={props.postListType} category={postFilterState.category} post={item}/></List.Item>}
                    style={{marginBottom:'25px', minHeight:'90vh'}}>
                    {loading && (
                    <div style={{marginTop:'5vh', textAlign:'center'}}>
                        <Spin />
                    </div>
                    )}
                </List>
                </ConfigProvider>
            </InfiniteScroll>
        </div>
    );
}
export default PostList;