import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import {triggerAboutModal, triggerRulesModal, triggerContactModal} from '../../Actions/Modal';

const SiteInfoMenu = (props: any) => {
    const dispatch = useDispatch();
    
    const onAboutClick = () => {
        dispatch(triggerAboutModal());
    };

    const onRulesClick = () => {
        dispatch(triggerRulesModal());
    };

    const onContactClick = () => {
        dispatch(triggerContactModal());
    };

    return (
        <div style={{width:'100%', textAlign:'center'}}>
            <Button type={'text'} onClick={onAboutClick}>About</Button> -
            <Button type={'text'} onClick={onRulesClick}>Rules</Button> -
            <Button type={'text'} onClick={onContactClick}>Contact</Button>
        </div>
    );
}

export default SiteInfoMenu;