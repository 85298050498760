import React, {useState, useEffect} from 'react';
import {Input, Form, Checkbox, Button, Row, Col} from 'antd';
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useDispatch } from 'react-redux';
import {triggerTermsModal, triggerPrivacyModal} from '../../Actions/Modal'
import Utils from '../../Utils';

interface SignupFormProps {
    onSubmit: Function;
    visible: boolean;
}

const SignupForm = (props: SignupFormProps) => {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const signUp = (email: string, displayName: string, password: string) => {
        setLoading(true);
        firestore.collection('users').where('displayName', '==', displayName).get().then((query) => {
            if(query.empty) {
                console.log("GOT then ", query);
                firebase.createUser(
                    { email, password },
                    { displayName, email }
                  ).then(result => {
                    console.log("THEN SIGNUP");
                    console.log(result);
                    firebase.updateAuth(
                        {
                            displayName: displayName,
                            /*photURL: string*/
                        }
                    ).then(result => {
                        form.resetFields();
                        props.onSubmit();
                        setLoading(false);
                    });
                }).catch(error => {
                    setErrorMessage('* ' + Utils.formatFirebaseError(error.message));
                    setLoading(false);
                    console.log("ERROR SIGNUP");
                });
            } else {
                setErrorMessage("* Username is already taken.");
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
        });
    }

    const onFinish = (values: any) => {
        console.log(values);
        // Check captcha
        if(values.info == undefined || values.info == "") {
            if(values.userAgreement) {
                signUp(values.email, values.username, values.password);
            } else {
                setErrorMessage("Please accept the terms.");
            }
        } else {
            props.onSubmit();
        }
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        form.resetFields();
    },[props.visible]);

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{
                userAgreement: false,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            >
            <Form.Item
                name="username"
                hasFeedback
                rules={[
                    { required: true, message: 'Please create a username!'},
                    { min: 3, message: 'Username must be 3-20 characters!'},
                    { max: 20, message: 'Username must be 3-20 characters!'}
                ]}
            >
                <Input  prefix={<UserOutlined/>} placeholder={"Username"}/>
            </Form.Item>
            <Form.Item
                name="email"
                hasFeedback
                rules={[
                    { required: true, message: 'Please input your email!'},
                    { type: 'email', message: 'Must be a valid email!'}
                ]}
            >
                <Input prefix={<MailOutlined/>} placeholder={"Email"}/>
            </Form.Item>
            <Form.Item
                name="password"
                hasFeedback
                rules={[
                    { required: true, message: 'Please create a password!'},
                    { min: 8, message: 'Password must be atleast 8 characters!'},
                    /*{ max: 128, message: 'Password must be 5-20 characters!'}, max length?*/
                ]}
            >
                <Input.Password prefix={<LockOutlined/>} placeholder={"Password"}/>
            </Form.Item>
            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                {
                    required: true, message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }

                    return Promise.reject('Passwords dont match!');
                    },
                }),
                ]}
            >
                <Input.Password prefix={<LockOutlined/>} placeholder={"Confirm"}/>
            </Form.Item>
            <div className="cap">
                <Form.Item
                    name="info"
                    label="Info"
                >
                    <Input />
                </Form.Item>
            </div>

            <Form.Item 
             name="userAgreement"
             valuePropName="checked">
                <Checkbox>
                    <i>I agree and accept HaHrchives&nbsp;
                        <a onClick={()=> {dispatch(triggerTermsModal(true))}}>Terms of Use</a>&nbsp;and&nbsp;
                        <a onClick={()=> {dispatch(triggerPrivacyModal(true))}}>Privacy Policy</a>.
                    </i>
                </Checkbox>
            </Form.Item>

            <Form.Item>
                <Button type="primary" block={true} loading={loading} htmlType="submit">
                    Submit
                </Button>
                <br/>
                <span style={{color:'red', fontSize:'10px'}}><i>{errorMessage}</i></span>
            </Form.Item>
        </Form>             
    );
}

export default SignupForm;
