import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Layout, Row, Col} from 'antd';
import headerLogo from '../../headerLogo.svg';
import SearchBar from '../Input/SearchBar';
import UserControls from '../Buttons/ButtonGroups/UserControls';
import MobileSideMenuButton from '../Buttons/MobileSideMenuButton';
import { useSelector, useDispatch } from 'react-redux';
import { resetPostFilter } from '../../Actions/PostFilter';
import { DEVICES } from '../../Consts';

const {Header} = Layout;

const AppHeader = (props: any) => {
    const dispatch = useDispatch();
    const windowState = useSelector((state: any) => state.window);

    const onLogoClick = () => {
        dispatch(resetPostFilter());
    };

    return (
        <Header style={{ position: 'fixed', zIndex: 5, width: '100%' }} className = "AppHeader">
            <Row>
                <Col span = {windowState.device <= DEVICES.Tablet ? 4 : 7}>
                    {windowState.device <= DEVICES.Tablet ? 
                    <MobileSideMenuButton/> :
                    <Link to="/" onClick={onLogoClick}>
                        <img src={headerLogo} className="AppLogo" alt="logo" />
                    </Link>
                    }
                </Col>
                <Col span = {windowState.device <= DEVICES.Tablet ? 16 : 10}>
                    <Row justify={"center"}>
                        <Col style={{width:'100%', maxWidth:'600px'}}>
                        <SearchBar/>
                        </Col>
                    </Row>
                </Col>
                <Col span = {windowState.device <= DEVICES.Tablet ? 4 : 7}>
                    <UserControls/>
                </Col>
            </Row>
        </Header>
    );
}
export default AppHeader;
