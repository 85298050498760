import { SIDE_MENU_VISIBLE } from '../Actions/SideMenu';

const initialState = {
  visible: false
};

function sideMenuReducer(state = initialState, action: any) {
  switch (action.type) {
      case SIDE_MENU_VISIBLE:
          return {
              visible: action.visible
          };
  }
  return state;
}

export default sideMenuReducer;