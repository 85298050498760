import React from 'react';
import {Layout, Row, Col, Button} from 'antd';
import logo from '../../funnyBook.svg';
import { YEAR_MAX } from '../../Consts';
import {triggerTermsModal, triggerPrivacyModal} from '../../Actions/Modal';
import { useDispatch } from 'react-redux';

const {Footer} = Layout;

const AppFooter = (props: any) => {
    const dispatch = useDispatch();
    
    const onTermsClick = () => {
        dispatch(triggerTermsModal(false));
    }

    const onPrivacyClick = () => {
        dispatch(triggerPrivacyModal(false));
    }
    return (
        <Footer className = "Footer">
                <Row justify={'center'} align={'middle'} style={{height:'20vh'}}>
                    <Col>
                        <img src={logo} style={{width:'55px', height:'55px'}} alt="logo" />
                    </Col>
                    <Col style={{fontSize:'12px', paddingLeft:'48px'}}>
                        <Button type={'text'} size={'small'}  style={{fontSize:'12px'}} onClick={onTermsClick}>Terms Of Use</Button><br/>
                        <Button type={'text'} size={'small'}  style={{fontSize:'12px'}} onClick={onPrivacyClick}>Privacy Policy</Button><br/>
                        <span>	&copy;{YEAR_MAX}</span>
                    </Col>
                </Row>
        </Footer>
    );  
}
export default AppFooter;
