import { ADD_REMOVE_COMMENT } from '../Actions/CommentList';

const initialState = {
  reload: 0
};

function commentListReducer(state = initialState, action: any) {
    switch (action.type) {
        case ADD_REMOVE_COMMENT:
            return {
                reload: state.reload + 1
            }
        default : break;
    }
   return state;
}

export default commentListReducer;