import React from 'react';
import { Divider } from 'antd';
import logo from '../../funnyBook.svg';

const AboutInfo = (props: any) => {

    return (
        <div style={{fontSize:'12px'}}>
            <div style={{fontSize:'18px', fontWeight:'bold', width:'100%', textAlign:'center'}}>Welcome to the HaHrchives!&ensp;
            <img src={logo} style={{width:'32px', height:'32px'}} alt="logo" />
            </div>

            <p>&emsp;The place where you can discover, vote on, and learn about the <i>funniest</i> internet content!</p>

            <Divider orientation="left" style={{marginBottom:'0px'}}>
                FAQ
            </Divider>
            <dl>
                <dt>&emsp;- "What is the funniest internet video of the year?"</dt>
                <dt>&emsp;- "What is the funniest internet meme of the year?"</dt>
                <dt>&emsp;- "What is the funniest internet video of all time?"</dt>
                <dt>&emsp;- "What is the funniest internet trend of 2020?"</dt>
            </dl>
            <Divider style={{marginTop:'0px'}}/>

            <p>&emsp;We need your help, to answer these important questions and more. Our kids and grandkids, are growing up in a digital world, with an overwhelming amount of content.
                Millions of hilarious videos and images are uploaded daily, but only a handful achieve true, laugh out loud, viral status. This is the place, to surface only the best,
                and teach them the history of the internet, through comedy.</p>
        </div>
    );
}

export default AboutInfo;