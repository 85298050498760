import React, {useState, useEffect} from 'react';
import { Form, Button, Input, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isLoaded, useFirestore } from 'react-redux-firebase';
import { triggerCommentListReload } from '../../Actions/CommentList';
import Utils from '../../Utils';

const { TextArea } = Input;

interface AddCommentFormProps {
    onCancelSubmit: Function;
    postId: string;
}

const AddCommentForm = (props: AddCommentFormProps) => {
    const auth = useSelector((state: any) => state.firebase.auth);
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [placeholder, setPlaceholder] = useState("");

    const onFinish = (values: any) => {
        if(!isEmpty(auth)) {
            setLoading(true);
            const comment = {
                comment: values.comment,
                created: Utils.getFirebaseTimeStamp(), 
                postId: props.postId,
                displayName: auth.displayName,
                userId: auth.uid,
                likes: 0,
                dislikes: 0,
            }
            let batch = firestore.batch();
            let postRef = firestore.collection('posts').doc(props.postId);
            batch.update(postRef, {comments: firestore.FieldValue.increment(1)});
            let commentRef = firestore.collection('posts').doc(props.postId).collection('comments').doc();
            batch.set(commentRef, comment);
            batch.commit().then((success) => {
                setLoading(false);
                props.onCancelSubmit();
                dispatch(triggerCommentListReload());
            }).catch((error) => {
                setLoading(false);
                props.onCancelSubmit();
            });
            console.log(values.comment);
        } else {
            Utils.showLoginMessage(dispatch);
        }
    };

    const onCancel = () => {
        props.onCancelSubmit();
    };

    useEffect(() => {
        let mounted = true;
            if(!isEmpty(auth) && isLoaded(auth)) {
                setPlaceholder("Commenting as " + auth.displayName + "...");
            } else {
                setPlaceholder("Create an account to comment...");
            }

        return () => {
            // Fix for state update on unmounted component.
            mounted = false;
        }
    }, [auth])

    return(
        <>
        <Form onFinish={onFinish} onFinishFailed={onCancel}>
            <Form.Item name="comment">
                <TextArea rows={4} placeholder={placeholder}/>
            </Form.Item>
            <Form.Item style={{marginBottom:'0px'}}>
                <Row>
                    <Button htmlType="submit" loading={loading} type="primary">
                        Add Comment
                    </Button>
                    <Button onClick={onCancel} type="ghost" style={{marginLeft:'6px'}}>
                        Cancel
                    </Button>
                </Row>
            </Form.Item>
        </Form>
        </>
    );
};

export default AddCommentForm;
