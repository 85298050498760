import React, {useState} from 'react';
import {Button} from 'antd'
import { RedoOutlined, UserOutlined } from '@ant-design/icons'

interface ViewPendingPostsProps {
    onViewPendingClick: Function;
}

const ViewPendingPostsButton = (props: ViewPendingPostsProps) => {
    const [buttonText, setButtonText] = useState('View Pending Posts');
    const [pendingActive, setPendingActive] = useState(true);

    const onViewPendingClick = () => {
        const text = pendingActive ? 'View My Posts' : 'View Pending Posts';
        setButtonText(text);
        setPendingActive(!pendingActive);
        props.onViewPendingClick();
    }

    return (
        <>
            <Button type={'primary'} block={true} onClick={onViewPendingClick} icon={pendingActive ? <RedoOutlined/> : <UserOutlined/>}>{buttonText}</Button>
        </>
    );
}
export default ViewPendingPostsButton;
