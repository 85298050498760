import React from 'react';
import {Button} from 'antd';
import { useDispatch } from 'react-redux';
import {triggerLoginModal} from '../../Actions/Modal';

const LoginButton = (props: any) => {
  const dispatch = useDispatch();

  const loginButtonClick = () => {
    dispatch(triggerLoginModal());
  }

  return (
      <div>
        <Button type="primary" onClick={loginButtonClick}>Login</Button>
      </div>
  );

}
export default LoginButton;
