import React, { useState, useEffect } from 'react';
import { Row, Divider, ConfigProvider, Spin, List, Empty } from 'antd';
import PostComment from './PostComment';
import { CommentOutlined, LeftOutlined } from '@ant-design/icons';
import AddCommentButton from '../Buttons/AddCommentButton';
import { useFirestore } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import Utils from '../../Utils';
import { triggerFooterVisible } from '../../Actions/Footer';

interface CommentSectionProps {
    postId: string;
    numComments: number;
}

const PAGE_SIZE: number = 30;

const CommentSection = (props: CommentSectionProps) => {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const [commentList, setCommentList] = useState(Array<IComment>());
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [startAfterItem, setStartAfterItem] = useState(null);
    const [numComments, setNumComments] = useState(props.numComments);
    const commentListState = useSelector((state: any) => state.commentList);

    const onLoadMore = () => {
        console.log("LOAD MORE");
        buildCommentList(false);
    }

    const buildCommentList = (reloadQuery: boolean) => {
        if(props.postId == "") {
            return;
        }
        setLoading(true);
        let comments: Array<IComment> = reloadQuery ? new Array<IComment>() : commentList;
        let commentQueryParams: any = firestore.collection('posts').doc(props.postId).collection('comments').orderBy("created", "asc");
        if(startAfterItem != null) {
            commentQueryParams = commentQueryParams.startAfter(startAfterItem);
        }
        let commentQuery: Promise<any> | undefined = commentQueryParams.limit(PAGE_SIZE).get();
        commentQuery?.then((query) => {
            console.log("Length? " + query.docs.length);
            console.log("LENGTH < PAGE SIZE? ", query.docs.length < PAGE_SIZE);
            if(query.docs.length < PAGE_SIZE) {
                console.log("No more docs?");
                // No more docs
                setHasMore(false);
                dispatch(triggerFooterVisible(true));
            } else {
                // Set start after, last doc.
                setStartAfterItem(query.docs[query.docs.length-1]);
                console.log("StartAfter " + query.docs[query.docs.length-1].data().comment);
            }
            query.forEach(function(doc: any) {
                let comment = Utils.docToIComment(doc);
                comments.push(comment);
            });
            setCommentList(comments);
            updateNumComments();
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    }

    const updateNumComments = () => {
        firestore.collection('posts').doc(props.postId).get().then((doc) => {
            let post = doc.data();
            if(post != undefined && post.comments != undefined && post.comments != numComments) {
                console.log("not undefined?");
                setNumComments(post.comments);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });  
    };

    useEffect(() => {
        if(commentList.length != 0) {
            // Building the list will be triggered by loadMore() when empty. Dont call it twice.
            // This is for reloading on add comment
            buildCommentList(true);
        }
        console.log("comment list state ", commentListState.reload);
    },[commentListState.reload]);

    useEffect(() => {
        // Set footer visible on unmount.
        return () => {
            dispatch(triggerFooterVisible(true));
        };
      }, []); 
    

    return (
        <div style={{width:'100%'}}>
            <Row>
                <span style={{fontSize:'18px', marginRight:'16px', marginTop:'-2px'}}>{numComments} <CommentOutlined/></span>
                <AddCommentButton postId={props.postId}/>
            </Row>
            <Divider style={{marginBottom:'0px'}}/>

            <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={onLoadMore}
            hasMore={!loading && hasMore}
            useWindow={true}
            >
                <ConfigProvider renderEmpty={() => <div style={{padding:'25px'}}><i>No comments found.</i></div>}>
                <List
                    dataSource={commentList}
                    renderItem={comment =><List.Item><PostComment comment = {comment}/></List.Item>}>
                    {loading && (
                    <div style={{marginTop:'12px', textAlign:'center'}}>
                        <Spin />
                    </div>
                    )}
                </List>
                </ConfigProvider>
            </InfiniteScroll>
        </div>
    );
}

export default CommentSection;
