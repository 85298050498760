import React from 'react';
import {Menu, Row, Col, Divider, Checkbox } from 'antd';
import { FireFilled, ExclamationCircleFilled, HeartFilled, CalendarTwoTone } from '@ant-design/icons';
import YearSelector from '../Buttons/YearSelector';
import CreatePostButton from '../Buttons/CreatePostButton';
import PostTypeMenu from './PostTypeMenu';
import { useSelector, useDispatch } from 'react-redux';
import {setCategory, setAllTime, setYear, setMediaType} from '../../Actions/PostFilter'

interface PostListNavigationMenuProps {
}

const PostListNavigationMenu = (props: PostListNavigationMenuProps) => {
    const dispatch = useDispatch();
    const postFilterState = useSelector((state: any) => state.postFilter);

    const onTypeChange = (mediaType: string) => {
        if(postFilterState.mediaType != mediaType) {
            dispatch(setMediaType(mediaType));
        }
    }

    const onCategoryChange = (e: any) => {
        if(postFilterState.category != e.key) {
            dispatch(setCategory(e.key));
        }
    }

    const onYearChange = (year: number) => {
        if(postFilterState.year != year) {
            dispatch(setYear(year));
        }
    }
    
    const onAllTimeChange = () => {
        dispatch(setAllTime(!postFilterState.allTime));
    }

    return (
        <>
            <div style={{textAlign:'center', fontSize:'24px', paddingBottom:'12px'}}>
                <CalendarTwoTone twoToneColor={'rgba(75,0,75,1)'} style={{marginRight:'5px'}}/>
                {postFilterState.allTime ? 
                            <span><b>All Time</b>
                            </span>
                        :   <b>{postFilterState.year}</b>
                }
            </div>
            <Row justify="center">
                <Col>
                    <PostTypeMenu onTypeChange={onTypeChange} selected={postFilterState.mediaType}/>
                </Col>
            </Row>
            <Divider style={{marginBottom:'8px', marginTop:'0px'}}/>
            <Menu onClick={onCategoryChange} theme="light" mode="vertical" selectedKeys={[postFilterState.category]} defaultSelectedKeys={[postFilterState.category]} style={{backgroundColor:'rgba(255,255,255,1)'}}>
                <Menu.Item key="Recent">
                    <ExclamationCircleFilled/> Recent
                </Menu.Item>
                <Menu.Item key="Top">
                    <FireFilled/> Top
                </Menu.Item>
                <Menu.Item key="Favorites">
                    <HeartFilled/> Favorites
                </Menu.Item>
            </Menu>
            <Divider style={{marginBottom:'0px', marginTop:'8px'}}/>
            <Row justify="center">
                <YearSelector onYearChange={onYearChange} enabled={!postFilterState.allTime} selected={postFilterState.year}/>
            </Row>
            <Divider style={{marginBottom:'8px', marginTop:'0px'}}/>
            <Row justify="center">
                <Checkbox onChange={onAllTimeChange} checked={postFilterState.allTime}>All time</Checkbox>
            </Row>
            <Divider style={{marginTop:'8px'}}/>
            <Row justify="center" style={{paddingBottom:'0'}}>
                <CreatePostButton/>
            </Row>
        </>
    );
}

export default PostListNavigationMenu;