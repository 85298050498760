import React from 'react';
import {Row, Col, Button} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {LeftOutlined, RightOutlined} from '@ant-design/icons'
import {setYear} from '../../Actions/PostFilter'
import { YEAR_MAX, YEAR_MIN, POST_LIST_TYPE } from '../../Consts';
import Utils from '../../Utils';

interface PostListHeaderProps {
    postListType: POST_LIST_TYPE,
    postFilterState: any,
}

const PostListHeader = ( props: PostListHeaderProps ) => {
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.firebase.auth);

    const getHeaderLayout = () => {
        let contentType = Utils.getMediaTypeString(props.postFilterState.mediaType);
        if(props.postListType == POST_LIST_TYPE.User_Posts || props.postListType == POST_LIST_TYPE.Pending_Posts) {
            const headerText = props.postListType == POST_LIST_TYPE.User_Posts ? "My Posts:" : "Pending Approval";
            return (
                <div style={{width:'100%', textAlign: 'center', fontSize:'24px'}}><b>{headerText}</b></div>
            );
        }
        if(props.postFilterState.searchTerm !== '') {
            return (
                <div style={{width:'100%', textAlign: 'center', color:'rgba(200,200,200,255)'}}>Search Results: <i>"{props.postFilterState.searchTerm}"</i></div>
            );
        }
        if(props.postFilterState.allTime) {
            let allTimeText = YEAR_MIN.toString() + "  -  " + YEAR_MAX.toString();
            return (
                <Row justify="center" style={{color:'rgba(200,200,200,255)'}}>
                    <i>{props.postFilterState.category} {contentType}:</i>  &emsp; {allTimeText}
                </Row>
            );
        } else {
            let nextYear = Math.min(props.postFilterState.year+1, YEAR_MAX);
            let showNext = props.postFilterState.year+1 <= YEAR_MAX;
            let prevYear = Math.max(props.postFilterState.year-1, YEAR_MIN);
            let showPrev = props.postFilterState.year-1 >= YEAR_MIN;
            const onNextClick = () => {
                dispatch(setYear(nextYear));
            }
            const onPrevClick = () => {
                dispatch(setYear(prevYear));
            }
            return (
                <Row style={{color:'rgba(200,200,200,255)'}}>
                    <Col span={7}>
                        {showPrev &&
                        <div>
                            <Button type="text" onClick={onPrevClick}><LeftOutlined/> <span>{prevYear}</span></Button>
                        </div>}
                    </Col>
                    <Col span={10} style={{justifyContent:'center', display:'flex'}} >
                        <span style={{alignSelf:'center'}}><i>{props.postFilterState.category} {contentType}</i> {props.postFilterState.year}</span>
                    </Col>
                    <Col span={7}>
                        {showNext && 
                        <div style={{float:'right'}}>
                            <Button type="text" onClick={onNextClick}><span>{nextYear}</span> <RightOutlined/></Button>
                        </div>
                        }
                    </Col>
                </Row>
            );
        }
        return (
            <div>Header</div>
        );
    }

    return (
        <>
            {getHeaderLayout()}
        </>
    );
};

export default PostListHeader;
