import React, {useState} from 'react';
import {Button, Tooltip} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import AddCommentForm from '../Input/AddCommentForm';

interface AddCommentButtonProps {
    postId: string;
}

const AddCommentButton = (props: AddCommentButtonProps) => {
    const [commentFormOpen, setCommentFormVisible] = useState(false);

    const onAddComment = () => {
        setCommentFormVisible(true);
    }

    const onFormCancel = () => {
        setCommentFormVisible(false);
    }

    return (
        <>
        {!commentFormOpen ? 
        <Tooltip placement="right" title={'Add Comment'} arrowPointAtCenter>
            <Button onClick={onAddComment} shape={"circle"} size={'small'} icon={<PlusOutlined/>}/>  
        </Tooltip>
        :
        <div style={{width:'100%'}}>   
            <br/>
            <AddCommentForm postId={props.postId} onCancelSubmit={onFormCancel}/>
        </div>} 
        </>
    );
}
export default AddCommentButton;
