import React from 'react';
import {Empty} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import {triggerLoginModal} from '../../Actions/Modal';
import { HeartOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Utils from '../../Utils';
import { YEAR_MAX, POST_LIST_TYPE } from '../../Consts';

interface EmptyListProps {
    postListType: POST_LIST_TYPE,
    postFilterState: any,
}

const EmptyList = ( props: EmptyListProps ) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector((state: any) => state.firebase.auth);

    const getEmptyInfo = () => {
        if(props.postListType == POST_LIST_TYPE.Pending_Posts) {
            // Empty "Pending posts"
            return (<span>No Pending Posts</span>);
        } else if(props.postListType == POST_LIST_TYPE.User_Posts) {
            if(isEmpty(auth)) {return}
            // Empty "My Posts"
            return (<span>Create a post to view it here!<br/> 
                <a onClick={() => history.push('/create/'+auth.uid, {defaultYear: YEAR_MAX})}>Add One?</a></span>);
        } else {
            let contentType = Utils.getMediaTypeString(props.postFilterState.mediaType);
            if(props.postFilterState.category == "Favorites") { 
                if(isEmpty(auth)) {
                    return (<span>View your favorite posts! <br/>
                        <a onClick={() => dispatch(triggerLoginModal())}>Login?</a></span>);       
                }
                // Empty "Favorites"
                return (props.postFilterState.allTime ?
                        <span><HeartOutlined/> posts to view them here!</span> :
                        <span>No favorite {contentType} found in year <i>{props.postFilterState.year}</i><br/>
                        </span>);
            } else {
                if(isEmpty(auth)) {
                    return (<span>No {contentType} found in year <i>{props.postFilterState.year}</i><br/> 
                        <a onClick={() => dispatch(triggerLoginModal())}>Login and add one?</a></span>);      
                }
                // Empty Public lists
                return (<span>No {contentType} found in year <i>{props.postFilterState.year}</i><br/> 
                        <a onClick={() => history.push('/create/'+auth.uid, {defaultYear: props.postFilterState.year})}>Add One?</a></span>);
            } 
        }
    }

    return (
        <Empty description={getEmptyInfo()}/>
    );
};

export default EmptyList;
