import React from 'react';
import {Button, Tooltip} from 'antd';
import {FlagTwoTone} from '@ant-design/icons'
import {triggerFlagPostModal} from '../../Actions/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import Utils from '../../Utils';

interface FlagPostButtonProps {
  postId: string;
}

const FlagPostButton = (props: FlagPostButtonProps) => {
  const auth = useSelector((state: any) => state.firebase.auth);
  const dispatch = useDispatch();

  const onClick = (e: any) => {
    if(!isEmpty(auth) && isLoaded(auth)) {
      const flagPostProps: Map<string, any> = new Map([["postId", props.postId]]);
      dispatch(triggerFlagPostModal(flagPostProps));
    } else {
      Utils.showLoginMessage(dispatch);
    }
    e.stopPropagation();
  }

  return (
      <>
      <Tooltip placement="bottomRight" title={"Report"} arrowPointAtCenter>
          <Button type="text" onClick={onClick} icon={<FlagTwoTone twoToneColor="rgba(200,50,50,1)"/>}></Button>
      </Tooltip>
      </>
  );

}
export default FlagPostButton;
