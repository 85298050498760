import React from 'react';
import {Row, Button, Modal, Tooltip} from 'antd';
import { isEmpty, useFirestore } from 'react-redux-firebase'
import firebase from 'firebase/app';
import {CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleFilled} from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux';
import Utils from '../../../Utils';
import { triggerPostListReload } from '../../../Actions/List';

interface AcceptDenyPostProps {
    postId:string
}

const AcceptDenyPost = (props: any) => {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.firebase.auth);

    const onAcceptPostClick = (e: any) => {
        if(!isEmpty(auth)) {
            const onOkayClick = () => {
                let pendingPostRef = firestore.collection('pendingPosts').doc(props.postId);
                pendingPostRef.get().then((doc)=> {
                    let batch = firestore.batch();
                    let postRef = firestore.collection('posts').doc();
                    let docData = doc.data();
                    batch.set(postRef, {
                        title: docData?.title, 
                        history: docData?.history,
                        likes:0, 
                        upvotes:0,
                        url: docData?.url,
                        urlType: docData?.urlType,
                        year: docData?.year,
                        created: docData?.created,
                        uploaderDisplayName : docData?.uploaderDisplayName,
                        userId: docData?.userId,
                        searchTerms: docData?.searchTerms,
                    });
                    batch.delete(pendingPostRef);
                    batch.commit().then((success) => {
                        console.log("Success Accepted post", success);
                        dispatch(triggerPostListReload());
                    }).catch((error) => {
                        console.log("Error Accepted post batch", error);
                    });
                }).catch((error)=> {
                    console.log("Error accept post", error);
                });
            }
            Modal.confirm({
                title: 'Accept Post?',
                content: 'This post will be made public...',
                maskClosable: true,
                cancelText: 'Cancel',
                okText: 'Ok',
                icon: <ExclamationCircleFilled />,
                onOk: onOkayClick,
                onCancel: () => {Modal.destroyAll()},
            });
        } else {
            Utils.showLoginMessage(dispatch);
        }
        e.stopPropagation();
    };

    const onDenyPostClick = (e: any) => {
        if(!isEmpty(auth)) {
            const onOkayClick = () => {
                firestore.collection('pendingPosts').doc(props.postId).delete().then((success)=> {
                    dispatch(triggerPostListReload());
                }).catch((error)=> {
                    console.log("Error pending post delete");
                });
            }
            Modal.confirm({
                title: 'Deny Post?',
                content: 'This post will be deleted...',
                maskClosable: true,
                cancelText: 'Cancel',
                okText: 'Ok',
                icon: <ExclamationCircleFilled />,
                onOk: onOkayClick,
                onCancel: () => {Modal.destroyAll()},
            });
        } else {
            Utils.showLoginMessage(dispatch);
        }
        e.stopPropagation();
    };

    return (
        <div style={{fontSize:'15px'}}>
            <Row justify = "center">
                <Tooltip placement="bottom" title={"Accept Post"} arrowPointAtCenter>
                    <Button type="link" onClick={onAcceptPostClick} icon={<CheckCircleOutlined/>} style={{color:'green'}}></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Deny Post"} arrowPointAtCenter>
                    <Button type="link" onClick={onDenyPostClick} icon={<CloseCircleOutlined/>} style={{color:'red'}}></Button>
                </Tooltip>
            </Row>
        </div>
    );
}

export default AcceptDenyPost;
