import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import {Row, Col, Avatar, Divider} from 'antd'
import {UserOutlined} from '@ant-design/icons'
import PostList from '../../Lists/PostList';
import DeleteAccountButton from '../../Buttons/DeleteAccountButton';
import ViewPendingPostsButton from '../../Buttons/ViewPendingPostsButton';
import { DEVICES, POST_LIST_TYPE } from '../../../Consts';

interface MobileAccountDetailsProps {
    userRole: string;
}

const MobileAccountDetails = (props: MobileAccountDetailsProps) => {
    const auth = useSelector((state: any) => state.firebase.auth);
    const windowState = useSelector((state: any) => state.window);
    const [listType, setListType] = useState(POST_LIST_TYPE.User_Posts);

    const onViewPendingClick = () => {
        const newType = listType == POST_LIST_TYPE.User_Posts ? POST_LIST_TYPE.Pending_Posts : POST_LIST_TYPE.User_Posts;
        setListType(newType);
    }

    return (
        <Col span={24}>
            <Row justify={"center"}>
                <Col span={20} style={{marginTop:'40px',textAlign:'center'}}>
                    <div style={{fontSize:'24px'}}><b>Account Details:</b></div>
                    <div style={{width:'100%', marginBottom:'14px', marginTop:'14px'}}>
                    <Avatar style={{ backgroundColor: 'lightblue'}} size={75} icon={<UserOutlined />} />
                    </div>
                    <div style={{fontSize:'14px', textAlign:'start'}}>
                        <p>Username: <i>{auth.displayName}</i></p>
                        <p>Email: <i>{auth.email}</i></p>
                        {props.userRole == 'Moderator'  && 
                                <><p>Role: <i>Moderator</i></p>
                                  <Row justify={"center"}> <Col span={(windowState.device) < DEVICES.MobileLg ? 20 : 14}><ViewPendingPostsButton onViewPendingClick={onViewPendingClick}/></Col> </Row>
                                </>}
                    </div>
                    <br/>
                    <Row justify={"center"}> <Col span={(windowState.device) < DEVICES.MobileLg ? 20 : 14}><DeleteAccountButton/></Col> </Row>
                </Col>
            </Row>
            <Divider/>
            <Row justify={"center"}>
                <Col span={(windowState.device) < DEVICES.Tablet ? 24 : 20}>
                    <PostList postListType={listType}/>
                </Col>
            </Row>
        </Col>    
    );
}

export default MobileAccountDetails;