import React, {useState, useEffect} from 'react';
import {Input, Form, Modal, Button} from 'antd';
import { MailOutlined} from '@ant-design/icons';
import { useFirebase } from "react-redux-firebase";
import { useDispatch } from 'react-redux';
import {triggerLoginModal} from '../../Actions/Modal'
import {sendPasswordResetEmail} from "firebase/auth"

interface ResetPasswordFormProps {
    onSubmit: Function;
    visible: boolean;
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onGoToLogin = () => {
        dispatch(triggerLoginModal());
    }

    const onFinish = (values: any) => {
        var auth = firebase.auth();
        setLoading(true);
        const onCancelClick = () => {
            Modal.destroyAll();
        }
        sendPasswordResetEmail(auth, values.email).then(() => {
          setLoading(false);
          Modal.confirm({
                zIndex: 1001,
                title: 'Recovery email sent!',
                content: 'Go back to login?',
                maskClosable: true,
                okText: 'Ok',
                cancelText: 'Cancel',
                onOk: onGoToLogin,
                onCancel: onCancelClick,
            });
        }).catch((error) => {
            setErrorMessage('* ' + error.message);
        });
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        // Unmounted component bug?
        form.resetFields();
    },[props.visible]);

    return (
        <Form
            name="basic"
            form={form}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            >
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: 'Please input your email!'},
                ]}
            >
                <Input prefix={<MailOutlined/>} placeholder={"Email"}/>
            </Form.Item>

            <Form.Item>
                <Button type="primary" block={true} loading={loading} htmlType="submit">
                    Submit
                </Button>
                <br/>
                <span style={{color:'red', fontSize:'10px'}}><i>{errorMessage}</i></span>
            </Form.Item>
            <div style={{width:'100%', textAlign:'center', fontSize:'12px'}}>
                <span>
                    <a onClick={onGoToLogin}><i><b>Back to login</b></i></a>
                </span>
            </div>
        </Form>    
    );
}

export default ResetPasswordForm;