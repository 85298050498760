import React from 'react';
import {Affix, Button} from 'antd';
import {ToTopOutlined} from '@ant-design/icons'

const BackToTop = (props: any) => {

    const onBackToTopClick = () => {
        window.scrollTo(0,0);
    };

    return (
        <>
            <Affix style={{float:'right'}} offsetBottom={25}>
                <Button onClick={onBackToTopClick} shape="round" icon={<ToTopOutlined/>} type="default">
                    Top
                </Button>
            </Affix>
        </>
    );

}
export default BackToTop;
