import React, {useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from './Components/Layouts/AppHeader';
import AppContent from './Components/Layouts/AppContent';
import AppFooter from './Components/Layouts/AppFooter';
import Layout from 'antd/es/layout';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import {triggerDeviceUpdate} from './Actions/Window';
import Utils from './Utils';
import ReactGA from "react-ga4";

const App = () => {
  const dispatch = useDispatch();
  const footerState = useSelector((state: any) => state.footer);
  const windowState = useSelector((state: any) => state.window);
  const windowStateRef = useRef(windowState);
  ReactGA.initialize('G-5B4KZ2XF8F');

  useEffect(() => {
    window.addEventListener('resize', () => {
      let device = Utils.getDevice(window.innerWidth);
      if(windowStateRef.current.device != device) {
        console.log("Updating Device");
        console.log("Device ", device);
        console.log("WindowState device", windowStateRef.current.device);
        dispatch(triggerDeviceUpdate(device))
      }
    });
    ReactGA.send("pageview");
  }, []);
  
  useEffect(() => {
    // To use windowState in the event listener, have to update the reference
    windowStateRef.current = windowState;
  },[windowState]);

  return (
    <Layout className = "App">
      <Router>
        <AppHeader/>
        <AppContent/>
        {footerState.visible && <AppFooter/>}
      </Router>
    </Layout>
  );
}

export default App;